<p-card id="test">
    <div style="float: right; cursor: pointer; color: purple;" (click)="goBack()"><i class="pi pi-times"></i></div>
    <br>
    <form #testForm="ngForm" id="test2Form" name="test2Form" (ngSubmit)="submit()">
        <table class="table1">
            <tr>
                <td colspan="5" class="center">TEST 2: COMPREHENSION/TOETS 2: BEGRIP</td>
            </tr>
            <tr>
                <td colspan="5">
                    <b>DISCONTINUE </b>the test after 4 consecutive zero scores.<br>
                    <b>STAAK </b>die toets na 4 agtereenvolgende nultellings.
                </td>
            </tr>
            <tr>
                <td colspan="2" class="center">Item</td>
                <td colspan="2" class="center">Response<br>Respons</td>
                <td class="center" style="width: 40px;">Score<br>Telling</td>
            </tr>
            <tr>
                <td colspan="5" class="center bold bg-gray-500">Card A</td>
            </tr>
            <tr *ngFor="let q of cardA; index as i;">
                <td style="width: 40px;">{{q.number}}</td>
                <td style="width: 170px;">{{q.item}}<br>{{q.itemAfrikaans}}</td>
                <td style="border-right: none; width: 30px;"><sup>{{q.extra}}</sup></td>
                <td style="border-left: none;"><input #input [id]="'input' + i" [name]="'input' + i" type="text"
                        maxlength="500" [(ngModel)]="q.response" class="input" style="width: 100%"></td>
                <td><input #score [id]="'score' + i" [name]="'score' + i" type="number" [(ngModel)]="q.score" min="0" maxlength="2"
                        (change)="calculateTotalScore()" class="center" style="width: 60px;"></td>
            </tr>
            <tr>
                <td colspan="5" class="center bold bg-orange-200">Card B</td>
            </tr>
            <tr *ngFor="let q of cardB; index as i;">
                <td>{{q.number}}</td>
                <td></td>
                <td style="border-right: none;">{{q.extra}}</td>
                <td style="border-left: none;"></td>
                <td></td>
            </tr>
            <tr>
                <td colspan="4" class="bold bg-gray-500">TOTAL SCORE</td>
                <td><input name="totalScore" type="number" class="center" style="width: 60px;" [ngModel]="totalScore" disabled></td>
            </tr>
        </table>
        <p>*A comprehensive answer or two specific reasons./'n Omvattende antwoord of twee besondere redes.<br>
            **Examples of two general guidelines./Voorbeelde van twee algemene riglyne.</p>
        <p>Comments/Opmerkings:</p>
        <textarea d="comments" name="comments" rows="3" cols="83" pInputTextarea
            [(ngModel)]="testInstance.comments"></textarea>
        <br>
        <p-button [raised]="true" type="submit" label="Submit" styleClass="mr-2" [disabled]="testForm.invalid"></p-button>
        <p-button [outlined]="true" type="button" [label]="backButton" (onClick)="goBack()"></p-button>
    </form>
</p-card>