import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputGroupModule } from 'primeng/inputgroup';
import { InputGroupAddonModule } from 'primeng/inputgroupaddon';
import { RadioButtonModule } from 'primeng/radiobutton';
import { TesteeDTO } from '../../../shared/dtos/testee.dto';
import { CardModule } from 'primeng/card';
import { CalendarModule } from 'primeng/calendar';
import { CommonModule } from '@angular/common';
import { TesteeService } from '../../../shared/services/testee.service';
import { ApiResponse } from '../../../shared/models/apiResponse';
import { DateService } from '../../../shared/services/date.service';
import { MessageService } from 'primeng/api';
import { AutoCompleteCompleteEvent, AutoCompleteModule } from 'primeng/autocomplete';
import { UserDTO } from '../../../shared/dtos/user.dto';
import { InputTextModule } from 'primeng/inputtext';
import { TesterService } from '../../../shared/services/tester.service';
import { SchoolDTO } from '../../../shared/dtos/school.dto';

@Component({
  selector: 'app-testee-add-edit',
  standalone: true,
  imports: [
    FormsModule,
    InputGroupModule,
    InputGroupAddonModule,
    RadioButtonModule,
    ButtonModule,
    CardModule,
    CalendarModule,
    CommonModule,
    InputTextModule,
    AutoCompleteModule
  ],
  templateUrl: './testee-add-edit.component.html',
  styleUrl: './testee-add-edit.component.scss'
})
export class TesteeAddEditComponent implements OnInit {

  @Input() testee!: TesteeDTO
  @Output() onGoBack = new EventEmitter<void>();

  title: string = '';
  isEdit = false;
  tester: UserDTO;
  genders: { id: string, name: string }[];
  races: { id: string, name: string }[];
  backButton = 'Cancel';
  schools: SchoolDTO[];
  selectedSchool: SchoolDTO;
  filteredSchools: SchoolDTO[];

  constructor(private testeeService: TesteeService,
    private dateService: DateService,
    private messageService: MessageService,
    private testerService: TesterService
  ) {
    this.genders = [
      { id: '1', name: 'Female' },
      { id: '2', name: 'Male' },
      { id: '3', name: 'Other' }
    ];

    this.races = [
      { id: '1', name: 'African / Afrikaan' },
      { id: '2', name: 'Caucasian / Blank' },
      { id: '3', name: 'Coloured / Bruin' },
      { id: '4', name: 'Indian / Indiër' },
      { id: '5', name: 'Asian / Asiër' },
      { id: '6', name: 'Other / Ander' }
    ];
  }

  ngOnInit() {
    this.getSchools();

    if (this.testee.id) {
      this.isEdit = true;
      this.title = `Edit Testee ${this.testee.nameAndSurname}`;
      // this.getTestee();
      this.testee.genderIdString = this.testee.genderId.toString();
      this.testee.raceIdString = this.testee.raceId.toString();
      this.testee.dateOfBirth = this.dateService.stringToDate(this.testee.dateOfBirthString);
      this.backButton = 'Back';
    } else {
      this.isEdit = false;
      this.title = `Add Testee`;
      this.testee = new TesteeDTO();
      this.testee.genderId = 1;
      this.testee.genderIdString = '1';
      this.testee.raceId = 1;
      this.testee.raceIdString = '1';
      this.testee.schoolStatusId = 1;
      this.testee.testingLanguage = 1;
      this.getTester();
      this.backButton = 'Cancel';
    }
  }

  getSchools() {
    this.testeeService.getSchools().subscribe(
      {
        next: (response: ApiResponse) => {
          this.schools = response.result;
          if (this.testee && this.testee.schoolId)
            this.selectedSchool = this.schools.find(s => s.id == this.testee.schoolId)!;
        }
      });
  }

  filterSchool(event: AutoCompleteCompleteEvent) {
    let filtered: any[] = [];
    let query = event.query;

    for (let i = 0; i < (this.schools as any[]).length; i++) {
        let school = (this.schools as any[])[i];
        if (school.name.toLowerCase().indexOf(query.toLowerCase()) == 0) {
            filtered.push(school);
        }
    }

    this.filteredSchools = filtered;
}

  getTester() {
    this.testerService.getLoggedInTester().subscribe(
      {
        next: (response: ApiResponse) => {
          this.tester = response.result;
          this.testee.testerNameAndSurname = this.tester.name;
          this.testee.hpcsaRegisteredProfession = this.tester.hpcsaRegisteredProfession;
          this.testee.hpcsaRegistrationNumber = this.tester.hpcsaRegistrationNumber;
        }
      });
  }

  submit() {
    this.testee.dateOfBirthString = this.dateService.dateToString(this.testee.dateOfBirth);
    this.testee.genderId = parseInt(this.testee.genderIdString);
    this.testee.raceId = parseInt(this.testee.raceIdString);
    if (this.selectedSchool && this.selectedSchool.id)
      this.testee.schoolId = this.selectedSchool.id;  
    else {
      this.testee.schoolId = 0;
      this.testee.schoolName = this.selectedSchool as unknown as string;

      if (this.schools.find(s => s.name === this.testee.schoolName)) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'School already exists. Pease select it from the dropdown and then try again.' });
        return;
      }
    }

    this.testeeService.upsertTestee(this.testee).subscribe(
      {
        next: (response: ApiResponse) => {
          this.testee = response.result;

          if (this.isEdit)
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Testee updated successfully' });
          else {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Testee added successfully' });
            this.goBack();
          }
        }
      });
  }

  calculateAge(dateOfBirth: Date): { years: number; months: number } {
    const today = new Date();
    let years = today.getFullYear() - dateOfBirth.getFullYear();
    let months = today.getMonth() - dateOfBirth.getMonth();

    if (today.getDate() < dateOfBirth.getDate()) {
      months--;
    }

    if (months < 0) {
      years--;
      months += 12;
    }

    return { years, months };
  }

  changeDateOfBirth() {
    let age = this.calculateAge(this.testee.dateOfBirth);
    this.testee.ageYears = age.years;
    this.testee.ageMonths = age.months;
  }

  goBack() {
    this.onGoBack.emit();
  }

}
