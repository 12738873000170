import { Component, OnInit, ViewChild, viewChild } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { FormsModule, NgForm } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';
import { CommonModule } from '@angular/common';
import { ToastModule } from 'primeng/toast';
import { MessageService } from 'primeng/api';
import { RouterModule } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { InputTextModule } from 'primeng/inputtext';
import { RegisterationRequestDTO } from '../../shared/dtos/registration-request.dto';
import { AuthService } from '../../shared/services/auth.service';
import { TesterService } from '../../shared/services/tester.service';
import { DropdownModule } from 'primeng/dropdown';
import { AddressDTO } from '../../shared/dtos/address.dto';

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [
    FormsModule,
    ButtonModule,
    TooltipModule,
    CommonModule,
    ToastModule,
    RouterModule,
    InputTextModule,
    DropdownModule
  ],
  templateUrl: './register.component.html',
  styleUrl: './register.component.scss'
})
export class RegisterComponent implements OnInit {

  @ViewChild('registerForm') registerForm: NgForm;

  model: RegisterationRequestDTO;
  passwordInfo: string = 'Password must be at least 8 characters long and contain at least one number, one uppercase letter, one lowercase letter, and one special character.'
  registrationSubmitted: boolean = false;
  showPassword: boolean = false;
  passwordInputType: string = 'password';
  paswordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/;
  languages: { label: string, value: number }[];
  provinces: { label: string, value: number }[];

  constructor(private authService: AuthService,
    private messageService: MessageService,
    private testerService: TesterService
  ) { }

  ngOnInit(): void {
    this.model = new RegisterationRequestDTO();
    this.model.address = new AddressDTO();
    this.languages = this.testerService.languages;
    this.provinces = this.testerService.provinces;
    document.body.style.backgroundSize = "100% 185%";
    document.body.style.backgroundPosition = "center";
    document.body.style.height = "120%";
  }

  submit() {
    if (!this.paswordRegex.test(this.model.password)) {
      this.messageService.add({
        severity: 'error', summary: 'Error',
        detail: 'Password must be at least 8 characters long and contain at least one number, one uppercase letter, one lowercase letter, and one special character.'
      });
      return;
    }

    if (this.registerForm.invalid) {
      return;
    }

    this.authService.register(this.model).subscribe({
      next: () => { this.registrationSubmitted = true; }
    });
  }

  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;

    if (this.showPassword) {
      this.passwordInputType = 'text';
    } else {
      this.passwordInputType = 'password';
    }
  }
}
