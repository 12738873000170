import { Injectable } from "@angular/core";

@Injectable({ providedIn: 'root' })
export class DateService {

    constructor() { }

    dateToString(date: Date | string) {
        if (typeof date === 'string')
            date = new Date(date);

        let year = date.getFullYear();
        let month = date.getMonth() + 1;
        let day = date.getDate();

        let formattedMonth: string = month < 10 ? '0' + month : month.toString();
        let formattedDay: string = day < 10 ? '0' + day : day.toString();

        return `${year}/${formattedMonth}/${formattedDay}`;
    }    

    stringToDate(dateString: string) {
        let dateParts = dateString.split('/');
        let year = parseInt(dateParts[0]);
        let month = parseInt(dateParts[1]) - 1;
        let day = parseInt(dateParts[2]);

        return new Date(year, month, day);
    }
}