import { SchoolDTO } from "./school.dto";
import { TestInstanceDTO } from "./test-instance.dto";
import { UserDTO } from "./user.dto";

export class TesteeDTO {
    id: number;
    nameAndSurname: string;
    dateOfBirth: Date;
    dateOfBirthString: string;
    ageYears: number;
    ageMonths: number;
    genderId: number;
    genderIdString: string;
    otherGender: string | null;
    raceId: number;
    raceIdString: string;   
    otherRace: string | null;
    ethnicity: string;
    firstLanguage: string;
    otherLanguage1: string | null;
    otherLanguage2: string | null;
    otherLanguage3: string | null;
    testingLanguage: number;
    testingLanguageString: string;
    schoolName: string;
    grade: number;
    schoolType: string;
    province: string;
    languageOfInstruction: string;
    schoolStatusId: number;
    schoolStatusIdString: string;
    schoolQuintile: number;
    fatherOrGaurdian1Occupation: string;
    fatherOrGaurdian1Qualification: string;
    motherOrGaurdian2Occupation: string | null;
    motherOrGaurdian2Qualification: string | null;
    testerNameAndSurname: string;
    hpcsaRegisteredProfession: string;
    hpcsaRegistrationNumber: string;
    challenges: string | null;
    comments: string | null;
    testerId: number;
    createdDate: Date;
    createdDateString: string;
    schoolId: number;

    testInstances: TestInstanceDTO[] | null;
    tester: UserDTO | null;
    school: SchoolDTO | null;
}