import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { TestService } from '../../../../shared/services/test.service';
import { ApiResponse } from '../../../../shared/models/apiResponse';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { QuestionDTO } from '../../../../shared/dtos/question.dto';
import { CommonModule } from '@angular/common';
import { Question2DTO } from '../../../../shared/dtos/question2.dto';
import { TestInstanceDTO } from '../../../../shared/dtos/test-instance.dto';
import { InputTextModule } from 'primeng/inputtext';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { AnswerDTO } from '../../../../shared/dtos/answer.dto';
import { CardModule } from 'primeng/card';
import { DateService } from '../../../../shared/services/date.service';
import { RouterLink } from '@angular/router';
import { Card } from '../../../../shared/enums/card.enum';
import { Subtest } from '../../../../shared/enums/subtest.enum';
import { TesteeDTO } from '../../../../shared/dtos/testee.dto';
import { Language } from '../../../../shared/enums/language.enum';

@Component({
  selector: 'app-ssais-r-test-1',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ButtonModule,
    InputTextareaModule,
    RouterLink,
    CardModule
  ],
  templateUrl: './test-1.component.html',
  styleUrl: './test-1.component.scss'
})
export class Test1Component implements OnInit, AfterViewInit {

  @ViewChildren('inputEng') inputEngs: QueryList<ElementRef>;
  @ViewChildren('inputAfr') inputAfrs: QueryList<ElementRef>;

  @Input() testInstance: TestInstanceDTO;
  @Input() testee: TesteeDTO;
  @Output() onGoBack = new EventEmitter();

  get Language(): typeof Language { 
    return Language; 
  }

  questions: QuestionDTO[] = [];
  card1AEng: QuestionDTO[] = [];
  card1AAfr: QuestionDTO[] = [];
  card1BEng: QuestionDTO[] = [];
  card1BAfr: QuestionDTO[] = [];
  card2AEng: QuestionDTO[] = [];
  card2AAfr: QuestionDTO[] = [];
  card2BEng: QuestionDTO[] = [];
  card2BAfr: QuestionDTO[] = [];
  card3AEng: QuestionDTO[] = [];
  card3AAfr: QuestionDTO[] = [];
  card3BEng: QuestionDTO[] = [];
  card3BAfr: QuestionDTO[] = [];
  card4AEng: QuestionDTO[] = [];
  card4AAfr: QuestionDTO[] = [];
  card4BEng: QuestionDTO[] = [];
  card4BAfr: QuestionDTO[] = [];
  card5AEng: QuestionDTO[] = [];
  card5AAfr: QuestionDTO[] = [];
  card5BEng: QuestionDTO[] = [];
  card5BAfr: QuestionDTO[] = [];

  card1A: Question2DTO[] = [];
  card1B: Question2DTO[] = [];
  card2A: Question2DTO[] = [];
  card2B: Question2DTO[] = [];
  card3A: Question2DTO[] = [];
  card3B: Question2DTO[] = [];
  card4A: Question2DTO[] = [];
  card4B: Question2DTO[] = [];
  card5A: Question2DTO[] = [];
  card5B: Question2DTO[] = [];

  totalScoreEng: number;
  totalScoreAfr: number;
  questionResponseObject = {};
  backButton = 'Cancel';

  constructor(private testService: TestService,
    private messageService: MessageService,
    private dateService: DateService
  ) {
    this.questionResponseObject = {
      next: (response: ApiResponse) => {
        this.questions = response.result;
        this.card1AEng = this.questions.filter(q => q.card === Card.A1 && q.languageId === 1);
        this.card1AAfr = this.questions.filter(q => q.card === Card.A1 && q.languageId === 2);
        this.card1BEng = this.questions.filter(q => q.card === Card.B1 && q.languageId === 1);
        this.card1BAfr = this.questions.filter(q => q.card === Card.B1 && q.languageId === 2);
        this.card2AEng = this.questions.filter(q => q.card === Card.A2 && q.languageId === 1);
        this.card2AAfr = this.questions.filter(q => q.card === Card.A2 && q.languageId === 2);
        this.card2BEng = this.questions.filter(q => q.card === Card.B2 && q.languageId === 1);
        this.card2BAfr = this.questions.filter(q => q.card === Card.B2 && q.languageId === 2);
        this.card3AEng = this.questions.filter(q => q.card === Card.A3 && q.languageId === 1);
        this.card3AAfr = this.questions.filter(q => q.card === Card.A3 && q.languageId === 2);
        this.card3BEng = this.questions.filter(q => q.card === Card.B3 && q.languageId === 1);
        this.card3BAfr = this.questions.filter(q => q.card === Card.B3 && q.languageId === 2);
        this.card4AEng = this.questions.filter(q => q.card === Card.A4 && q.languageId === 1);
        this.card4AAfr = this.questions.filter(q => q.card === Card.A4 && q.languageId === 2);
        this.card4BEng = this.questions.filter(q => q.card === Card.B4 && q.languageId === 1);
        this.card4BAfr = this.questions.filter(q => q.card === Card.B4 && q.languageId === 2);
        this.card5AEng = this.questions.filter(q => q.card === Card.A5 && q.languageId === 1);
        this.card5AAfr = this.questions.filter(q => q.card === Card.A5 && q.languageId === 2);
        this.card5BEng = this.questions.filter(q => q.card === Card.B5 && q.languageId === 1);
        this.card5BAfr = this.questions.filter(q => q.card === Card.B5 && q.languageId === 2);

        if (this.card1AEng.length >= this.card1AAfr.length)
          this.card1AEng.forEach((q, index) => {
            this.card1A.push({ english: q, afrikaans: this.card1AAfr[index] });
          });
        else
          this.card1AAfr.forEach((q, index) => {
            this.card1A.push({ english: this.card1AEng[index], afrikaans: q });
          });

        if (this.card1BEng.length >= this.card1BAfr.length)
          this.card1BEng.forEach((q, index) => {
            this.card1B.push({ english: q, afrikaans: this.card1BAfr[index] ?? new QuestionDTO() });
          });
        else
          this.card1BAfr.forEach((q, index) => {
            this.card1B.push({ english: this.card1BEng[index] ?? new QuestionDTO(), afrikaans: q });
          });

        if (this.card2AEng.length >= this.card2AAfr.length)
          this.card2AEng.forEach((q, index) => {
            this.card2A.push({ english: q, afrikaans: this.card2AAfr[index] });
          });
        else
          this.card2AAfr.forEach((q, index) => {
            this.card2A.push({ english: this.card2AEng[index], afrikaans: q });
          });

        if (this.card2BEng.length >= this.card2BAfr.length)
          this.card2BEng.forEach((q, index) => {
            this.card2B.push({ english: q, afrikaans: this.card2BAfr[index] ?? new QuestionDTO() });
          });
        else
          this.card2BAfr.forEach((q, index) => {
            this.card2B.push({ english: this.card2BEng[index] ?? new QuestionDTO(), afrikaans: q });
          });

        if (this.card3AEng.length >= this.card3AAfr.length)
          this.card3AEng.forEach((q, index) => {
            this.card3A.push({ english: q, afrikaans: this.card3AAfr[index] });
          });
        else
          this.card3AAfr.forEach((q, index) => {
            this.card3A.push({ english: this.card3AEng[index], afrikaans: q });
          });

        if (this.card3BEng.length >= this.card3BAfr.length)
          this.card3BEng.forEach((q, index) => {
            this.card3B.push({ english: q, afrikaans: this.card3BAfr[index] ?? new QuestionDTO() });
          });
        else
          this.card3BAfr.forEach((q, index) => {
            this.card3B.push({ english: this.card3BEng[index] ?? new QuestionDTO(), afrikaans: q });
          });

        if (this.card4AEng.length >= this.card4AAfr.length)
          this.card4AEng.forEach((q, index) => {
            this.card4A.push({ english: q, afrikaans: this.card4AAfr[index] });
          });
        else
          this.card4AAfr.forEach((q, index) => {
            this.card4A.push({ english: this.card4AEng[index], afrikaans: q });
          });

        if (this.card4BEng.length >= this.card4BAfr.length)
          this.card4BEng.forEach((q, index) => {
            this.card4B.push({ english: q, afrikaans: this.card4BAfr[index] ?? new QuestionDTO() });
          });
        else
          this.card4BAfr.forEach((q, index) => {
            this.card4B.push({ english: this.card4BEng[index] ?? new QuestionDTO(), afrikaans: q });
          });

        if (this.card5AEng.length >= this.card5AAfr.length)
          this.card5AEng.forEach((q, index) => {
            this.card5A.push({ english: q, afrikaans: this.card5AAfr[index] });
          });
        else
          this.card5AAfr.forEach((q, index) => {
            this.card5A.push({ english: this.card5AEng[index], afrikaans: q });
          });

        if (this.card5BEng.length >= this.card5BAfr.length)
          this.card5BEng.forEach((q, index) => {
            this.card5B.push({ english: q, afrikaans: this.card5BAfr[index] ?? new QuestionDTO() });
          });
        else
          this.card5BAfr.forEach((q, index) => {
            this.card5B.push({ english: this.card5BEng[index] ?? new QuestionDTO(), afrikaans: q });
          });

        this.calculateTotalScoreEng()
        this.calculateTotalScoreAfr()
      }
    };
  }

  ngAfterViewInit(): void {
    const intervalId = setInterval(() => {
      if (this.inputEngs && this.inputEngs.first && this.inputEngs.first.nativeElement === document.activeElement) {
        clearInterval(intervalId);
      } else if (this.inputEngs && this.inputEngs.first) {
        this.inputEngs.first.nativeElement.focus();
      }
    }, 100);
  }

  ngOnInit(): void {

    this.testService.subtestcomponent = this;

    if (!this.testInstance) {
      this.testInstance = new TestInstanceDTO();
      this.testInstance.subtestId = Subtest.SSAISRTest1;
      this.getQuestions();
      this.backButton = 'Cancel';
    }
    else {
      this.getQuestionsWithAnswers();
      this.backButton = 'Back';
    }
  }

  getQuestionsWithAnswers() {
    this.testService.getQuestionsWithAnswers(this.testInstance.id).subscribe(this.questionResponseObject);
  }

  getQuestions() {
    this.testService.getQuestions(this.testInstance.subtestId).subscribe(this.questionResponseObject);
  }

  validateInput(event: KeyboardEvent, question: QuestionDTO, index: number, language?: string) {
    const value = event.key.toUpperCase();
    const allowedKeys = ['A', 'B', 'C', 'D', 'BACKSPACE', 'DELETE'];
    if (!allowedKeys.includes(value)) {
      event.preventDefault();
    } else {
      if (value === 'BACKSPACE' || value === 'DELETE') {
        question.response = '';
        question.score = 0;
        event.preventDefault();
      } else {
        question.response = value;
        if (question.answer === value) {
          question.score = 1;
        }
        else {
          question.score = 0;
        }
        if (language === 'eng') {
          this.moveToNextInputEng(index);
          this.calculateTotalScoreEng();
        }
        else {
          this.moveToNextInputAfr(index);
          this.calculateTotalScoreAfr();
        }

        event.preventDefault();
      }
    }
  }

  calculateTotalScoreEng() {
    this.totalScoreEng = 0;
    this.card1A.forEach(q => {
      if (q.english.score)
        this.totalScoreEng += q.english.score;
    });
    this.card1B.forEach(q => {
      if (q.english.score)
        this.totalScoreEng += q.english.score;
    });
    this.card2A.forEach(q => {
      if (q.english.score)
        this.totalScoreEng += q.english.score;
    });
    this.card2B.forEach(q => {
      if (q.english.score)
        this.totalScoreEng += q.english.score;
    });
    this.card3A.forEach(q => {
      if (q.english.score)
        this.totalScoreEng += q.english.score;
    });
    this.card3B.forEach(q => {
      if (q.english.score)
        this.totalScoreEng += q.english.score;
    });
    this.card4A.forEach(q => {
      if (q.english.score)
        this.totalScoreEng += q.english.score;
    });
    this.card4B.forEach(q => {
      if (q.english.score)
        this.totalScoreEng += q.english.score;
    });
    this.card5A.forEach(q => {
      if (q.english.score)
        this.totalScoreEng += q.english.score;
    });
    this.card5B.forEach(q => {
      if (q.english.score)
        this.totalScoreEng += q.english.score;
    });
  }

  calculateTotalScoreAfr() {
    this.totalScoreAfr = 0;
    this.card1A.forEach(q => {
      if (q.afrikaans.score)
        this.totalScoreAfr += q.afrikaans.score;
    });
    this.card1B.forEach(q => {
      if (q.afrikaans.score)
        this.totalScoreAfr += q.afrikaans.score;
    });
    this.card2A.forEach(q => {
      if (q.afrikaans.score)
        this.totalScoreAfr += q.afrikaans.score;
    });
    this.card2B.forEach(q => {
      if (q.afrikaans.score)
        this.totalScoreAfr += q.afrikaans.score;
    });
    this.card3A.forEach(q => {
      if (q.afrikaans.score)
        this.totalScoreAfr += q.afrikaans.score;
    });
    this.card3B.forEach(q => {
      if (q.afrikaans.score)
        this.totalScoreAfr += q.afrikaans.score;
    });
    this.card4A.forEach(q => {
      if (q.afrikaans.score)
        this.totalScoreAfr += q.afrikaans.score;
    });
    this.card4B.forEach(q => {
      if (q.afrikaans.score)
        this.totalScoreAfr += q.afrikaans.score;
    });
    this.card5A.forEach(q => {
      if (q.afrikaans.score)
        this.totalScoreAfr += q.afrikaans.score;
    });
    this.card5B.forEach(q => {
      if (q.afrikaans.score)
        this.totalScoreAfr += q.afrikaans.score;
    });
  }

  moveToNextInputEng(currentIndex: number) {
    const nextIndex = currentIndex + 1;
    const inputsArray = this.inputEngs.toArray();
    if (nextIndex < inputsArray.length) {
      inputsArray[nextIndex].nativeElement.focus();
    }
  }

  moveToNextInputAfr(currentIndex: number) {
    const nextIndex = currentIndex + 1;
    const inputsArray = this.inputAfrs.toArray();
    if (nextIndex < inputsArray.length) {
      inputsArray[nextIndex].nativeElement.focus();
    }
  }

  pushAnswers(card: Question2DTO[]) {

    card.forEach(q => {
      let answer = new AnswerDTO();
      answer.questionId = q.english.id;
      answer.response = q.english.response;
      answer.score = q.english.score;
      answer.id = q.english.answerId;

      let existingAnswer = this.testInstance.answers.find(x => x.questionId == answer.questionId);

      if (!existingAnswer)
        this.testInstance.answers!.push(answer);
      else if (answer.response && answer.score != null && existingAnswer) {
        existingAnswer.response = q.english.response;
        existingAnswer.score = q.english.score;
      }

      answer = new AnswerDTO();
      answer.questionId = q.afrikaans.id;
      answer.response = q.afrikaans.response;
      answer.score = q.afrikaans.score;
      answer.id = q.afrikaans.answerId;

      existingAnswer = this.testInstance.answers.find(x => x.questionId == answer.questionId);

      if (!existingAnswer)
        this.testInstance.answers!.push(answer);
      else if (answer.response && answer.score != null && existingAnswer) {
        existingAnswer.response = q.english.response;
        existingAnswer.score = q.english.score;
      }
    });
  }

  submit() {

    this.testInstance.answers = [];

    this.pushAnswers(this.card1A);
    // this.pushAnswers(this.card1B);
    this.pushAnswers(this.card2A);
    // this.pushAnswers(this.card2B);
    this.pushAnswers(this.card3A);
    // this.pushAnswers(this.card3B);
    this.pushAnswers(this.card4A);
    // this.pushAnswers(this.card4B);
    this.pushAnswers(this.card5A);
    // this.pushAnswers(this.card5B);

    this.testInstance.dateOfTesting = new Date();
    this.testInstance.dateOfTestingString = this.dateService.dateToString(this.testInstance.dateOfTesting);
    this.testInstance.testeeId = this.testee.id;

    if (this.testInstance.id)
      this.testService.isEdit = true;

    this.testService.upsertTestInstance(this.testInstance).subscribe(this.testService.upsertTestInstanceResponseObject);
  }

  goBack() {
    this.onGoBack.emit();
  }
}
