import { Component, OnInit } from '@angular/core';
import { ProgressSpinnerModule } from "primeng/progressspinner";
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastModule } from 'primeng/toast';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-verify-email',
  standalone: true,
  imports: [
    ProgressSpinnerModule,
    CommonModule,
    ToastModule
  ],
  templateUrl: './verify-email.component.html',
  styleUrl: './verify-email.component.scss'
})
export class VerifyEmailComponent implements OnInit {

  token: string;
  isVerified: boolean = false;
  busy: boolean = true;

  constructor(private authService: AuthService,
    private route: ActivatedRoute,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      const userId = params['userId'];
      const code = params['code'];
      this.verifyEmail(userId, code);
    });
  }

  verifyEmail(userId: string, code: string) {
    this.busy = true;
    this.authService.verifyEmail(userId, code).subscribe({
      next: () => { this.isVerified = true; },
      complete: () => { this.busy = false; }
    });
  }
}
