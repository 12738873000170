<p-card id="testees" [header]="'Testees'" *ngIf="!upsertTestee && !isTakeTest">
    <div class="formgrid grid">
        <div class="field col">
            <label for="school">School</label>
            <p-dropdown id="school" [options]="schools" optionLabel="name" optionValue="id"
                (onChange)="getTestees($event)" scrollHeight="600px" placeholder="Select a School"
                styleClass="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"></p-dropdown>
        </div>
        <div class="field col">
            <label for="from">From Year (testing date)</label>
            <p-dropdown id="from" (onChange)="getTestees($event)" [(ngModel)]="fromYear"
                [options]="fromYears" scrollHeight="600px"
                styleClass="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                [style]="{'appearance': 'auto'}"></p-dropdown>
        </div>
        <div class="field col">
            <label for="to">To Year (testing date)</label>
            <p-dropdown id="to" (onChange)="getTestees($event)" [(ngModel)]="toYear"
                [options]="toYears" scrollHeight="600px"
                styleClass="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                [style]="{'appearance': 'auto'}"></p-dropdown>
        </div>
    </div>
    <p-table #dt1 [value]="testees" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,25,50]" dataKey="id"
        [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="[
            'nameAndSurname', 
            'dateOfBirthString', 
            'ageYears',
            'testingLanguageString']" responsiveLayout="scroll" styleClass="p-datatable-gridlines" [rowHover]="true">
        <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
                <p-button label="Add New Testee" [raised]="true" icon="pi pi-plus" class="mr-2"
                    (click)="upsert()"></p-button>
                <p-button label="Reset" [raised]="true" icon="pi pi-filter-slash" (click)="clear(dt1)"></p-button>
                <span class="p-input-icon-left ml-auto">
                    <i class="pi pi-search"></i>
                    <input #filter pInputText type="text" (input)="onGlobalFilter(dt1,  $event)"
                        placeholder="Search keyword" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="nameAndSurname">
                    <div>
                        Name and Surname
                        <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="nameAndSurname"></p-sortIcon>
                        <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="nameAndSurname" display="menu"
                            placeholder="Name and Surname"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="dateOfBirthString">
                    <div>
                        Date of Birth
                        <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="dateOfBirthString"></p-sortIcon>
                        <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="dateOfBirthString" display="menu"
                            placeholder="Date of Birth"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="ageYears">
                    <div>
                        Age
                        <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="ageYears"></p-sortIcon>
                        <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="ageYears" display="menu" placeholder="Age"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="testingLanguageString">
                    <div>
                        Language of Testing
                        <p-sortIcon  pTooltip="Sort" tooltipPosition="top"field="testingLanguageString"></p-sortIcon>
                        <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="testingLanguageString" display="menu"
                            placeholder="Language of Testing"></p-columnFilter>
                    </div>
                </th>
                <th>Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-testee>
            <tr>
                <td>{{testee.nameAndSurname}}</td>
                <td>{{testee.dateOfBirthString}}</td>
                <td>{{testee.ageYears}}</td>
                <td>{{testee.testingLanguageString}}</td>
                <td>
                    <p-button [rounded]="true" [raised]="true" icon="pi pi-pencil" class="mr-2"
                        (click)="upsert(testee)" pTooltip="Make Amendments" tooltipPosition="top"></p-button>
                    <p-button [rounded]="true" [raised]="true" icon="pi pi-stopwatch" class="mr-2"
                        (click)="takeTest(testee)" severity="info" pTooltip="Enter Scores" tooltipPosition="top"></p-button>
                    <p-button [rounded]="true" [raised]="true" icon="pi pi-trash" severity="danger"
                        (click)="confirm(testee)" *ngIf="isAdmin" pTooltip="Delete Testee" tooltipPosition="top"></p-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-card>

<p-confirmDialog>
    <ng-template pTemplate="message" let-message>
        <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
            <i class="pi pi-exclamation-circle text-6xl text-primary-500"></i>
            <p>{{ message.message }}</p>
        </div>
    </ng-template>
</p-confirmDialog>

<app-testee-add-edit [testee]="selectedTestee" (onGoBack)="refresh()" *ngIf="upsertTestee"></app-testee-add-edit>

<app-ssais-r-test-1 *ngIf="isTakeTest && selectedSubtestId === SubtestEnum.SSAISRTest1" (onGoBack)="refresh()"
    [testee]="testee"></app-ssais-r-test-1>
<app-ssais-r-test-2 *ngIf="isTakeTest && selectedSubtestId === SubtestEnum.SSAISRTest2" (onGoBack)="refresh()"
    [testee]="testee"></app-ssais-r-test-2>
<app-ssais-r-test-3 *ngIf="isTakeTest && selectedSubtestId === SubtestEnum.SSAISRTest3" (onGoBack)="refresh()"
    [testee]="testee"></app-ssais-r-test-3>
<app-ssais-r-test-8 *ngIf="isTakeTest && selectedSubtestId === SubtestEnum.SSAISRTest8" (onGoBack)="refresh()"
    [testee]="testee"></app-ssais-r-test-8>