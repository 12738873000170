import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { UserDTO } from '../../dtos/user.dto';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { ApiResponse } from '../../models/apiResponse';
import { TesterService } from '../../services/tester.service';
import { CheckboxModule } from 'primeng/checkbox';

@Component({
  selector: 'app-tester',
  standalone: true,
  imports: [
    FormsModule,
    ButtonModule,
    CommonModule,
    DropdownModule,
    CheckboxModule
  ],
  templateUrl: './tester.component.html',
  styleUrl: './tester.component.scss'
})
export class TesterComponent implements OnInit {

  @Input() showBackButton: boolean;
  @Input() tester: UserDTO;
  @Output() onGoBack = new EventEmitter();

  languages: { label: string, value: number }[];
  provinces: { label: string, value: number }[];

  constructor(private testerService: TesterService,
    private messageService: MessageService) { }

  ngOnInit(): void {
    this.languages = this.testerService.languages;
    this.provinces = this.testerService.provinces;
  }

  submit() {
    if (this.showBackButton) {
      this.testerService.updateTester(this.tester).subscribe(
        {
          next: (response: ApiResponse) => {
            this.messageService.add({ severity: 'success', detail: 'Tester updated successfully.' });
          }
        });
    }
    else {
      this.testerService.updateLoggedInTester(this.tester).subscribe(
        {
          next: (response: ApiResponse) => {
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Profile updated successfully' });
          }
        });
    }
  }

  goBack() {
    this.onGoBack.emit();
  }
}
