import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { UserDTO } from '../../shared/dtos/user.dto';
import { DropdownModule } from 'primeng/dropdown';
import { ApiResponse } from '../../shared/models/apiResponse';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { TesterService } from '../../shared/services/tester.service';
import { Table, TableModule } from 'primeng/table';
import { CommonModule } from '@angular/common';
import { TesterEditComponent } from './tester-edit/tester-edit.component';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-activate-tester',
  standalone: true,
  imports: [
    CardModule,
    ButtonModule,
    FormsModule,
    DropdownModule,
    TableModule,
    CommonModule,
    TesterEditComponent,
    CheckboxModule,
    InputTextModule,
    TooltipModule
  ],
  templateUrl: './testers.component.html',
  styleUrl: './testers.component.scss'
})
export class TestersComponent implements OnInit {

  @ViewChild('filter') filter!: ElementRef;
  
  tester: UserDTO = new UserDTO();
  testers: UserDTO[] = [];
  selectedTester: UserDTO = new UserDTO();
  editTester = false;

  constructor(private testerService: TesterService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.getTesters();
  }

  edit(tester: UserDTO) {
    this.selectedTester = tester;
    this.editTester = true;    
  }

  getTesters() {
    this.testerService.getTesters().subscribe(
      {
        next: (response: ApiResponse) => {
          this.testers = response.result;
          this.testers.forEach(tester => {
            if (tester.isActivated) {
              tester.activeStatus = 'Active';
            } 
            else {
              tester.activeStatus = 'Inactive';
            }
          });
        }
      });
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal(
      (event.target as HTMLInputElement).value,
      'contains'
    );
  }

  clear(table: Table) {
    table.sortField = null;
    table.sortOrder = 0;
    table.clear();
    this.filter.nativeElement.value = '';
    this.getTesters();
  }

  refresh() {
    this.selectedTester = new UserDTO();
    this.editTester = false;
    this.getTesters();
  }
}
