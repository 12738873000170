<p-toolbar styleClass="shadow-6 m-0"
    [style]="{ 'border-radius': '0', 'border-style': 'none', 'background-color': 'white' }">
    <div class="p-toolbar-group-start">
        <button style="color: #883CAE"
            class="p-link inline-flex justify-content-center align-items-center mr-3"
            routerLink="/app/home"><img src="../../../assets/images/MM2 Home.jpg"></button>
        <button style="color: #883CAE"
            class="p-link inline-flex justify-content-center align-items-center h-2rem w-7rem mr-3"
            routerLink="/app/testers" *isAdmin>Testers</button>
        <button style="color: #883CAE"
            class="p-link inline-flex justify-content-center align-items-center h-2rem w-4rem mr-3"
            routerLink="/app/testees" (click)="refreshTesteePage()">Testees</button>
        <button style="color: #883CAE"
            class="p-link inline-flex justify-content-center align-items-center h-2rem w-7rem mr-3"
            routerLink="/app/test-instances" (click)="refreshTestPage()">Tests</button>
        <button style="color: #883CAE"
            class="p-link inline-flex justify-content-center align-items-center h-2rem w-3rem"
            routerLink="/app/excel">Export</button>
    </div>
    <div class="p-toolbar-group-end">
        <div class="flex align-items-center gap-2">
            <span class="font-bold" *ngIf="loggedInUser" style="color: #883CAE">Hi {{loggedInUser.firstName}}</span>
            <p-menu #menu1 [model]="items" [popup]="true"></p-menu>
            <button style="color: #883CAE"
                class="p-link inline-flex justify-content-center align-items-center h-3rem w-3rem"
                (click)="menu1.toggle($event)"><i class="pi pi-user text-2xl"></i></button>
        </div>
    </div>
</p-toolbar>