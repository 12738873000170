import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { MessageService } from 'primeng/api';
import { TesteeService } from '../../shared/services/testee.service';
import { Constants } from '../../shared/constants';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { InputNumberModule } from 'primeng/inputnumber';
import { ApiResponse } from '../../shared/models/apiResponse';
import { SchoolDTO } from '../../shared/dtos/school.dto';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { TesteeDTO } from '../../shared/dtos/testee.dto';
import { Language } from '../../shared/enums/language.enum';
import { Table, TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { TooltipModule } from 'primeng/tooltip';
import { RoleService } from '../../shared/services/role.service';

@Component({
  selector: 'app-excel',
  standalone: true,
  imports: [
    CardModule,
    ButtonModule,
    FormsModule,
    CommonModule,
    InputNumberModule,
    DropdownModule,
    TableModule,
    InputTextModule,
    TooltipModule
  ],
  templateUrl: './excel.component.html',
  styleUrl: './excel.component.scss'
})
export class ExcelComponent implements OnInit {

  @ViewChild('filter') filter!: ElementRef;

  datasheet1FileName = 'Excel Datasheet 1_Learner Biographical Data_SSAIS R Revision Project_Mindmuzik Media Research.xlsx';
  datasheet2FileName = 'Excel Datasheet 2_Data from Learner Answers_SSAIS R Revision Project_Mindmuzik Media Research.xlsx';
  filename: string;

  startAge: number = 5;
  endAge: number = 50;
  schools: SchoolDTO[] = [];
  selectedSchoolId: number;
  fromYears: number[];
  toYears: number[];
  fromYear: number;
  toYear: number;
  testees: TesteeDTO[] = [];
  isAdmin = this.roleService.isAdmin();

  downloadFileResponseObject = {
    next: (data: any) => {
      if (data.size > 22) {
        const blob = new Blob([data], { type: Constants.ExcelMimeType });
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = this.filename;
        a.click();
        URL.revokeObjectURL(url);
      } else {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: 'There is something wrong with the document.' });
      }
    }
  };

  constructor(
    private testeeService: TesteeService,
    private messageService: MessageService,
    private roleService: RoleService) { }

  ngOnInit(): void {
    this.getSchools();
    this.getAllTesteesEntrolledYears();
  }

  getAllTesteesEntrolledYears() {
    this.testeeService.getAllTesteesEnrolledYears().subscribe(
      {
        next: (response: ApiResponse) => {
          this.fromYears = response.result;
          this.toYears = response.result;

          this.fromYear = this.fromYears[0];
          this.toYear = this.toYears[this.toYears.length - 1];

          this.getTestees();
        }
      });
  }

  getSchools() {
    this.testeeService.getSchools().subscribe(
      {
        next: (response: ApiResponse) => {
          this.schools = response.result;

          if (this.isAdmin) {
            this.schools.unshift({ id: 0, name: 'All' });
          }
        }
      });
  }

  downloadTestees() {
    this.filename = this.datasheet1FileName;
    this.testeeService.getTesteesExcel(this.selectedSchoolId, this.fromYear, this.toYear, this.startAge, this.endAge).subscribe(this.downloadFileResponseObject);
  }

  downloadSsais_R() {
    this.filename = this.datasheet2FileName;
    this.testeeService.getSsais_RExcel(this.selectedSchoolId, this.fromYear, this.toYear, this.startAge, this.endAge).subscribe(this.downloadFileResponseObject);
  }

  getTestees(event?: DropdownChangeEvent) {

    if (!event)
      return;

    this.testeeService.getTestees(event.value, this.fromYear, this.toYear, this.startAge, this.endAge).subscribe({
      next: (response: ApiResponse) => {
        if (!response.result)
          return;

        this.testees = response.result;

        this.testees.forEach(t => {
          if (t.genderId === 1)
            t.genderIdString = 'Male';
          else if (t.genderId === 2)
            t.genderIdString = 'Female';
          else if (t.genderId === 3)
            t.genderIdString = 'Other';

          if (t.raceId === 1)
            t.raceIdString = 'African / Afrikaan';
          else if (t.raceId === 2)
            t.raceIdString = 'Caucasian / Blank';
          else if (t.raceId === 3)
            t.raceIdString = 'Coloured / Bruin';
          else if (t.raceId === 4)
            t.raceIdString = 'Indian / Indiër';
          else if (t.raceId === 5)
            t.raceIdString = 'Asian / Asiër';
          else if (t.raceId === 6)
            t.raceIdString = 'Other / Ander';

          if (t.testingLanguage === Language.English)
            t.testingLanguageString = 'English';
          else if (t.testingLanguage === Language.Afrikaans)
            t.testingLanguageString = 'Afrikaans';
          else if (t.testingLanguage === Language.Both)
            t.testingLanguageString = 'English & Afrikaans';

          if (t.schoolStatusId === 1)
            t.schoolStatusIdString = 'Higher';
          else if (t.schoolStatusId === 2)
            t.schoolStatusIdString = 'Lower';
        });
      }
    });
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal(
      (event.target as HTMLInputElement).value,
      'contains'
    );
  }

  clear(table: Table) {
    table.sortField = null;
    table.sortOrder = 0;
    table.clear();
    this.filter.nativeElement.value = '';
  }
}
