import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from "@angular/core";
import { RoleService } from "../services/role.service";

@Directive({
    selector: '[isAdmin]',
    standalone: true,
})
export class IsAdminDirective implements OnInit {

    constructor(private roleService: RoleService,
        private viewContainer: ViewContainerRef,
        private templateRef: TemplateRef<any>) { }

    ngOnInit() {
        this.viewContainer.clear();

        if (this.roleService.isAdmin()) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}