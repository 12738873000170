import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, Subject } from "rxjs";
import { ApiResponse } from "../models/apiResponse";
import { MessageService } from "primeng/api";
import { TestInstanceDTO } from "../dtos/test-instance.dto";

@Injectable({
    providedIn: 'root'
})
export class TestService {

    apiUrl = environment.apiUrl;
    subtestcomponent: any;
    isEdit = false;
    refreshTests = new Subject();

    upsertTestInstanceResponseObject = {
        next: (response: ApiResponse) => {
            this.subtestcomponent.testInstance = response.result;
            this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Test saved successfully' });
            if (!this.isEdit)
                this.subtestcomponent.goBack();
        }
    };

    constructor(private http: HttpClient,
        private messageService: MessageService
    ) { }

    getTests(): Observable<any> {
        return this.http.get(`${this.apiUrl}/test`);
    }

    isFirstOrAfterSixMonths(testeeId: number, testId: number, subtestId: number): Observable<any> {
        return this.http.get(`${this.apiUrl}/test/is-first-or-after-six-months?testeeId=${testeeId}&testId=${testId}&subtestId=${subtestId}`);
    }

    getQuestions(subtestId: number): Observable<any> {
        return this.http.get(`${this.apiUrl}/test/get-questions?subtestId=${subtestId}`);
    }

    getQuestionsWithAnswers(testInstanceId: number): Observable<any> {
        return this.http.get(`${this.apiUrl}/test/get-questions-with-answers?testInstanceId=${testInstanceId}`);
    }

    upsertTestInstance(testInstance: TestInstanceDTO): Observable<any> {
        return this.http.post(`${this.apiUrl}/test`, testInstance);
    }

    getTestInstances(schoolId: number, fromYear: number, toYear: number): Observable<any> {
        return this.http.get(`${this.apiUrl}/test/get-test-instances?schoolId=${schoolId}&fromYear=${fromYear}&toYear=${toYear}`);
    }

    deleteTest(testInstanceId: number): Observable<any> {
        return this.http.delete(`${this.apiUrl}/test/${testInstanceId}`);
    }
}