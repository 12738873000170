<p-toast [life]="10000"></p-toast>

<div class="px-5 min-h-screen flex justify-content-center align-items-center">
    <div class="border-1 surface-border surface-card border-round py-7 px-4 md:px-7 z-1 w-6">
        <div>
            <p-progressSpinner *ngIf="busy"></p-progressSpinner>
            <div *ngIf="isVerified && !busy">
                <div class="text-900 text-xl font-bold mb-2">Email verified</div>
                <span class="text-600 font-medium">Your email has been successfully verified and your account is pending
                    activation. As soon as it is activated by Mindconnect administration you will receive an email. You
                    can then proceed with login. Please note that it will take at least 2 working days for your account 
                    to be activated. Mindconnect office hours are 8am to 5pm from Monday to Friday.</span>
            </div>
            <div *ngIf="!isVerified && !busy">
                <div class="text-900 text-xl font-bold mb-2">Email Verification Failed</div>
                <span class="text-600 font-medium">Your email verification has failed. Please contact Mindconnect
                    administration for assistance.</span>
            </div>
        </div>
    </div>
</div>