import { AddressDTO } from "./address.dto";

export class UserDTO {
    id: number;
    userName: string;
    firstName: string;
    lastName: string;
    hpcsaRegisteredProfession: string;
    hpcsaRegistrationNumber: string;
    idNumber: string;
    practice: string;
    telHome: string;
    telWork: string;
    languageId: number;
    name: string;
    isActivated: boolean;
    activeStatus: string;

    address: AddressDTO;
}