<p-card id="test">
    <div style="float: right; cursor: pointer; color: purple;" (click)="goBack()"><i class="pi pi-times"></i></div>
    <br>
    <form #testForm="ngForm" id="test1Form" name="test1Form" (ngSubmit)="submit()">
        <table class="table1">
            <tr>
                <td colspan="10" class="center">TEST 1: VOCABULARY/TOETS 1 WOORDESKAT</td>
            </tr>
            <tr>
                <td colspan="10">
                    <b>DISCONTINUATION RULE FOR 7- TO 10-YEAR-OLD TESTEES</b><br>
                    <b>DISCONTINUE A CARD</b> after 6 consecutive failures (zero scores) and present the next card.<br>
                    <b>STAAKREËL VIR 7- TOT 10-JARIGE TOETSLINGE</b><br>
                    <b>STAAK 'N KAART</b> na 6 agtereenvolgende mislukkings (nultellings) en lê die volgende kaart voor.
                </td>
            </tr>
            <tr>
                <td colspan="5" class="center bg-gray-200">English</td>
                <td colspan="5" class="center bg-gray-200">Afrikaans</td>
            </tr>
            <tr>
                <td colspan="2" class="bg-gray-200">Item</td>
                <td class="bg-gray-200">Answer</td>
                <td class="bg-gray-200">Response</td>
                <td class="bg-gray-200">Score</td>
                <td colspan="2" class="bg-gray-200">Item</td>
                <td class="bg-gray-200">Antwoord</td>
                <td class="bg-gray-200">Respons</td>
                <td class="bg-gray-200">Telling</td>
            </tr>
            <tr>
                <td colspan="5" class="center bold bg-gray-500">Card 1A</td>
                <td colspan="5" class="center bold bg-gray-500">Kaart 1A</td>
            </tr>
            <tr *ngFor="let q of card1A; index as i;">
                <td>{{q.english.number}}</td>
                <td>{{q.english.item}}</td>
                <td class="center">{{q.english.answer}}</td>
                <td><input #inputEng [id]="'inputEng' + i" [disabled]="testee.testingLanguage === Language.Afrikaans"
                        [name]="'card1AEngResponse' + i" type="text" maxlength="1" minlength="1"
                        [ngModel]="q.english.response" (keydown)="validateInput($event, q.english, i, 'eng')"></td>
                <td><input [name]="'card1AEngScore' + i" type="number"
                        [ngModel]="q.english.score" disabled></td>
                <td>{{q.afrikaans.number}}</td>
                <td>{{q.afrikaans.item}}</td>
                <td class="center">{{q.afrikaans.answer}}</td>
                <td><input #inputAfr [id]="'inputAfr' + i" [disabled]="testee.testingLanguage === Language.English"
                        [name]="'card1AAfrResponse' + i" type="text" maxlength="1" minlength="1"
                        [ngModel]="q.afrikaans.response" (keydown)="validateInput($event, q.afrikaans, i)"></td>
                <td><input [name]="'card1AAfrScore' + i"
                        type="number" [ngModel]="q.afrikaans.score" disabled></td>
            </tr>
            <tr>
                <td colspan="5" class="center bold bg-orange-200">Card 1B</td>
                <td colspan="5" class="center bold bg-orange-200">Kaart 1B</td>
            </tr>
            <tr *ngFor="let q of card1B; index as i;">
                <td>{{q.english.number}}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{q.afrikaans.number}}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td colspan="5" class="center bold bg-gray-500">Card 2A</td>
                <td colspan="5" class="center bold bg-gray-500">Kaart 2A</td>
            </tr>
            <tr *ngFor="let q of card2A; index as i;">
                <td>{{q.english.number}}</td>
                <td>{{q.english.item}}</td>
                <td class="center">{{q.english.answer}}</td>
                <td><input #inputEng [id]="'inputEng' + i + 10" [disabled]="testee.testingLanguage === Language.Afrikaans"
                        [name]="'card2AEngResponse' + i + 10" type="text" maxlength="1" minlength="1"
                        [ngModel]="q.english.response" (keydown)="validateInput($event, q.english, i + 10, 'eng')"></td>
                <td><input [name]="'card2AEngScore' + i + 10" type="number"
                        [ngModel]="q.english.score" disabled></td>
                <td>{{q.afrikaans.number}}</td>
                <td>{{q.afrikaans.item}}</td>
                <td class="center">{{q.afrikaans.answer}}</td>
                <td><input #inputAfr [id]="'inputAfr' + i + 10" [disabled]="testee.testingLanguage === Language.English"
                        [name]="'card2AAfrResponse' + i + 10" type="text" maxlength="1" minlength="1"
                        [ngModel]="q.afrikaans.response" (keydown)="validateInput($event, q.afrikaans, i + 10)"></td>
                <td><input [name]="'card2AAfrScore' + i + 10"
                        type="number" [ngModel]="q.afrikaans.score" disabled></td>
            </tr>
            <tr>
                <td colspan="5" class="center bold bg-orange-200">Card 2B</td>
                <td colspan="5" class="center bold bg-orange-200">Kaart 2B</td>
            </tr>
            <tr *ngFor="let q of card2B; index as i;">
                <td>{{q.english.number}}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{q.afrikaans.number}}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td colspan="5" class="center bold bg-gray-500">Card 3A</td>
                <td colspan="5" class="center bold bg-gray-500">Kaart 3A</td>
            </tr>
            <tr *ngFor="let q of card3A; index as i;">
                <td>{{q.english.number}}</td>
                <td>{{q.english.item}}</td>
                <td class="center">{{q.english.answer}}</td>
                <td><input #inputEng [id]="'inputEng' + i + 20" [disabled]="testee.testingLanguage === Language.Afrikaans"
                        [name]="'card3AEngResponse' + i + 20" type="text" maxlength="1" minlength="1"
                        [ngModel]="q.english.response" (keydown)="validateInput($event, q.english, i + 20, 'eng')"></td>
                <td><input [name]="'card3AEngScore' + i + 20" type="number"
                        [ngModel]="q.english.score" disabled></td>
                <td>{{q.afrikaans.number}}</td>
                <td>{{q.afrikaans.item}}</td>
                <td class="center">{{q.afrikaans.answer}}</td>
                <td><input #inputAfr [id]="'inputAfr' + i + 20" [disabled]="testee.testingLanguage === Language.English"
                        [name]="'card3AAfrResponse' + i + 20" type="text" maxlength="1" minlength="1"
                        [ngModel]="q.afrikaans.response" (keydown)="validateInput($event, q.afrikaans, i + 20)"></td>
                <td><input [name]="'card3AAfrScore' + i + 20"
                        type="number" [ngModel]="q.afrikaans.score" disabled></td>
            </tr>
            <tr>
                <td colspan="5" class="center bold bg-orange-200">Card 3B</td>
                <td colspan="5" class="center bold bg-orange-200">Kaart 3B</td>
            </tr>
            <tr *ngFor="let q of card3B; index as i;">
                <td>{{q.english.number}}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{q.afrikaans.number}}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td colspan="5" class="center bold bg-gray-500">Card 4A</td>
                <td colspan="5" class="center bold bg-gray-500">Kaart 4A</td>
            </tr>
            <tr *ngFor="let q of card4A; index as i;">
                <td>{{q.english.number}}</td>
                <td>{{q.english.item}}</td>
                <td class="center">{{q.english.answer}}</td>
                <td><input #inputEng [id]="'inputEng' + i + 30" [disabled]="testee.testingLanguage === Language.Afrikaans"
                        [name]="'card4AEngResponse' + i + 30" type="text" maxlength="1" minlength="1"
                        [ngModel]="q.english.response" (keydown)="validateInput($event, q.english, i + 30, 'eng')"></td>
                <td><input [name]="'card4AEngScore' + i + 30" type="number"
                        [ngModel]="q.english.score" disabled></td>
                <td>{{q.afrikaans.number}}</td>
                <td>{{q.afrikaans.item}}</td>
                <td class="center">{{q.afrikaans.answer}}</td>
                <td><input #inputAfr [id]="'inputAfr' + i + 30" [disabled]="testee.testingLanguage === Language.English"
                        [name]="'card4AAfrResponse' + i + 30" type="text" maxlength="1" minlength="1"
                        [ngModel]="q.afrikaans.response" (keydown)="validateInput($event, q.afrikaans, i + 30)"></td>
                <td><input [name]="'card4AAfrScore' + i + 30"
                        type="number" [ngModel]="q.afrikaans.score" disabled></td>
            </tr>
            <tr>
                <td colspan="5" class="center bold bg-orange-200">Card 4B</td>
                <td colspan="5" class="center bold bg-orange-200">Kaart 4B</td>
            </tr>
            <tr *ngFor="let q of card4B; index as i;">
                <td>{{q.english.number}}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{q.afrikaans.number}}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
            </tr>
            <tr>
                <td colspan="5" class="center bold bg-gray-500">Card 5A</td>
                <td colspan="5" class="center bold bg-gray-500">Kaart 5A</td>
            </tr>
            <tr *ngFor="let q of card5A; index as i;">
                <td>{{q.english.number}}</td>
                <td>{{q.english.item}}</td>
                <td class="center">{{q.english.answer}}</td>
                <td><input #inputEng [id]="'inputEng' + i + 40" [disabled]="testee.testingLanguage === Language.Afrikaans"
                        [name]="'card5AEngResponse' + i + 40" type="text" maxlength="1" minlength="1"
                        [ngModel]="q.english.response" (keydown)="validateInput($event, q.english, i + 40, 'eng')"></td>
                <td><input [name]="'card5AEngScore' + i + 40" type="number"
                        [ngModel]="q.english.score" disabled></td>
                <td>{{q.afrikaans.number}}</td>
                <td>{{q.afrikaans.item}}</td>
                <td class="center">{{q.afrikaans.answer}}</td>
                <td><input #inputAfr [id]="'inputAfr' + i + 40" [disabled]="testee.testingLanguage === Language.English"
                        [name]="'card5AAfrResponse' + i + 40" type="text" maxlength="1" minlength="1"
                        [ngModel]="q.afrikaans.response" (keydown)="validateInput($event, q.afrikaans, i + 40)"></td>
                <td><input [name]="'card5AAfrScore' + i + 40"
                        type="number" [ngModel]="q.afrikaans.score" disabled></td>
            </tr>
            <tr>
                <td colspan="5" class="center bold bg-orange-200">Card 5B</td>
                <td colspan="5" class="center bold bg-orange-200">Kaart 5B</td>
            </tr>
            <tr *ngFor="let q of card5B; index as i;">
                <td>{{q.english.number}}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td>{{q.afrikaans.number}}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>                
            </tr>
            <tr>
                <td colspan="4" class="bold bg-gray-500">TOTAL SCORE</td>
                <td><input name="totalScoreEng" [ngModel]="totalScoreEng" disabled></td>
                <td colspan="4" class="bold bg-gray-500">TOTALE TELLING</td>
                <td><input name="totalScoreAfr" [ngModel]="totalScoreAfr" disabled></td>
            </tr>
        </table>
        <p>Comments/Opmerkings:</p>
        <textarea id="comments" name="comments" rows="3" cols="83" pInputTextarea
            [(ngModel)]="testInstance.comments"></textarea>
        <br>
        <p-button [raised]="true" type="submit" label="Submit" styleClass="mr-2" [disabled]="testForm.invalid"></p-button>
        <p-button [outlined]="true" type="button" [label]="backButton" (onClick)="goBack()"></p-button>
    </form>
</p-card>