import { Component, OnInit } from '@angular/core';
import { RouterOutlet } from '@angular/router';
import { PrimeNGConfig } from 'primeng/api';
import { AuthService } from './shared/services/auth.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
})
export class AppComponent implements OnInit {

  private currentVersion: string;
  version: string;

  constructor(private primengConfig: PrimeNGConfig,
    private authService: AuthService,
    private http: HttpClient
  ) { }

  ngOnInit() {
    this.primengConfig.ripple = true;

    this.currentVersion = localStorage.getItem('version')!;

    this.setVersion();

    setInterval(() => this.setVersion(), 30000);

    this.authService.autoLogin();
  }

  setVersion() {
    this.http.get('/assets/config.json').subscribe((data: any) => {
      this.version = data.version;

      if (this.currentVersion !== this.version) {
        localStorage.setItem('version', this.version);
        window.location.reload();
      }

      console.log('MM2 Version: ' + this.currentVersion);
    });
  }
}
