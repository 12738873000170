import { HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MessageService } from "primeng/api";

@Injectable({
    providedIn: 'root'
})
export class ToastrService {

    constructor(private messageService: MessageService) { }

    showToastrError(error: HttpErrorResponse) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: error?.error?.errorMessage ?? 'An error has occurred. Please contact IT.' });
    }
}