<div class="px-5 min-h-screen flex justify-content-center align-items-center">
    <div class="border-1 surface-border surface-card border-round py-7 px-4 md:px-7 z-1 w-5">
        <div class="mb-4" *ngIf="!passwordIsReset">
            <span class="text-600 font-medium">Reset your password</span>
        </div>
        <div class="p-input-icon-left w-full mb-4" *ngIf="!passwordIsReset">
            <i class="pi pi-lock"></i>
            <input id="newPassword" type="password" pInputText class="w-full md:w-25rem" placeholder="New Password" [(ngModel)]="newPassword">
        </div>
        <div class="p-input-icon-left w-full mb-4" *ngIf="!passwordIsReset">
            <i class="pi pi-lock"></i>
            <input id="confirmPassword" type="password" pInputText class="w-full md:w-25rem" placeholder="Confirm Password" [(ngModel)]="confirmPassword">
        </div>
        <small class="p-input-icon-left w-full mb-4" *ngIf="!(newPassword == null || newPassword === '' || confirmPassword == null || confirmPassword === '') && newPassword !== confirmPassword">
            Passwords do not match
        </small>
        <p-button [raised]="true" label="Submit" class="w-auto mr-2" (click)="resetPassword()" *ngIf="!passwordIsReset"
         [disabled]="newPassword == null || newPassword === '' || confirmPassword == null || confirmPassword === '' || newPassword !== confirmPassword"></p-button>
        <div *ngIf="passwordIsReset" class="mb-3">
            <span class="text-600 font-medium">Your password has been successfully reset.</span>
        </div>
        <p-button [raised]="true" routerLink="/login" *ngIf="passwordIsReset">Back to Login</p-button>
    </div>
</div>
