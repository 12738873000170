import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserDTO } from '../../../shared/dtos/user.dto';
import { TesterService } from '../../../shared/services/tester.service';
import { ApiResponse } from '../../../shared/models/apiResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { FormsModule } from '@angular/forms';
import { CardModule } from 'primeng/card';
import { CheckboxModule } from 'primeng/checkbox';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { TesterComponent } from '../../../shared/components/tester/tester.component';

@Component({
  selector: 'app-tester-edit',
  standalone: true,
  imports: [
    CardModule,
    FormsModule,
    ButtonModule,
    CheckboxModule,
    CommonModule,
    DropdownModule,
    TesterComponent
  ],
  templateUrl: './tester-edit.component.html',
  styleUrl: './tester-edit.component.scss'
})
export class TesterEditComponent implements OnInit {

  @Input() tester: UserDTO;
  @Output() onGoBack = new EventEmitter();

  heading: string;

  constructor(private testerService: TesterService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {    

    this.testerService.getTester(this.tester.id).subscribe(
      {
        next: (response: ApiResponse) => {
          this.tester = response.result;
          this.heading = 'Edit ' + this.tester.firstName + ' ' + this.tester.lastName;
        }
      });
  }  

  goBack() {
    this.onGoBack.emit();
  }
}
