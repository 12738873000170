import { Component, OnInit } from '@angular/core';
import { MessageService } from 'primeng/api';
import { ToastModule } from 'primeng/toast';
import { Router, RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CommonModule } from '@angular/common';
import { InputTextModule } from 'primeng/inputtext';
import { LoginRequestDTO } from '../../shared/dtos/login-request.dto';
import { AuthService } from '../../shared/services/auth.service';

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [
    FormsModule,
    ToastModule,
    ButtonModule,
    CommonModule,
    InputTextModule,
    RouterModule
  ],
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {

  model: LoginRequestDTO = new LoginRequestDTO();

  constructor(private messageService: MessageService,
    private router: Router,
    private authService: AuthService
  ) { }

  ngOnInit() {
    document.body.style.backgroundImage = "url('../../assets/images/MM2 Background Login.jpg')";
    document.body.style.backgroundRepeat = "no-repeat";
    document.body.style.backgroundSize = "cover";
    document.body.style.backgroundPosition = "center";
    document.body.style.height = "100%";
  }

  login() {
      this.authService.login(this.model).subscribe({
      next: () => {
        this.router.navigate(['/app']);
      }
    });
  }

  register() {
    this.router.navigate(['/register']);
  }
}
