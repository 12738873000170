import { HttpInterceptorFn, HttpRequest, HttpHandlerFn } from "@angular/common/http";

export const BasicAuthInterceptor: HttpInterceptorFn = (req: HttpRequest<unknown>, next:
  HttpHandlerFn) => {

  const token = JSON.parse(localStorage.getItem('accessToken')!);

  const modifiedReq = req.clone({
    headers: req.headers.set('Authorization', `Bearer ${token}`),
  });

  return next(modifiedReq);
};