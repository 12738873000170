<p-card header="Assigned Tests" *ngIf="tests && !editTest">
    <div class="formgrid grid">
        <div class="field col">
            <label for="school">School</label>
            <p-dropdown id="school" [options]="schools" optionLabel="name" optionValue="id"
                (onChange)="getTests($event)" scrollHeight="600px" placeholder="Select a School"
                styleClass="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"></p-dropdown>
        </div>
        <div class="field col">
            <label for="from">From Year (testing date)</label>
            <p-dropdown id="from" (onChange)="getTests($event)" [(ngModel)]="fromYear"
                [options]="fromYears" scrollHeight="600px"
                styleClass="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                [style]="{'appearance': 'auto'}"></p-dropdown>
        </div>
        <div class="field col">
            <label for="to">To Year (testing date)</label>
            <p-dropdown id="to" (onChange)="getTests($event)" [(ngModel)]="toYear"
                [options]="toYears" scrollHeight="600px"
                styleClass="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                [style]="{'appearance': 'auto'}"></p-dropdown>
        </div>
    </div>
    <p-table #dt1 [value]="tests" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,25,50]" dataKey="id"
        [showCurrentPageReport]="true" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="[
            'testee.nameAndSurname',
            'subtest.test.name',
            'subtestName',
            'dateOfTestingString']" responsiveLayout="scroll" styleClass="p-datatable-gridlines" [rowHover]="true">
        <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
                <p-button label="Reset" [raised]="true" icon="pi pi-filter-slash" (click)="clear(dt1)"></p-button>
                <span class="p-input-icon-left ml-auto">
                    <i class="pi pi-search"></i>
                    <input #filter pInputText type="text" (input)="onGlobalFilter(dt1,  $event)"
                        placeholder="Search keyword" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="testee.nameAndSurname">
                    <div>
                        Testee Name and Surname
                        <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="testee.nameAndSurname"></p-sortIcon>
                        <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="testee.nameAndSurname" display="menu"
                            placeholder="Testee Name and Surname"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="testee.nameAndSurname">
                    <div>
                        Test
                        <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="subtest.test.name"></p-sortIcon>
                        <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="subtest.test.name" display="menu"
                            placeholder="Test"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="subtestName">
                    <div>
                        Subtest
                        <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="subtestName"></p-sortIcon>
                        <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="subtestName" display="menu"
                            placeholder="Subtest"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="dateOfTestingString">
                    <div>
                        Date of Testing
                        <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="dateOfTestingString"></p-sortIcon>
                        <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="dateOfTestingString" display="menu"
                            placeholder="Date of Testing"></p-columnFilter>
                    </div>
                </th>
                <th>Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-test>
            <tr>
                <td>{{test.testee.nameAndSurname}}</td>
                <td>{{test.subtest.test.name}}</td>
                <td>{{test.subtestName}}</td>
                <td>{{test.dateOfTestingString}}</td>
                <td>
                    <p-button [rounded]="true" [raised]="true" icon="pi pi-pencil"  pTooltip="Edit Test" tooltipPosition="top"
                        class="p-button-rounded p-button-success mr-2" (onClick)="edit(test)"></p-button>
                    <p-button [rounded]="true" [raised]="true" icon="pi pi-trash" pTooltip="Delete Test" tooltipPosition="top"
                        class="p-button-rounded p-button-danger" severity="danger" (onClick)="confirm(test)"
                        *ngIf="isAdmin"></p-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-card>

<p-confirmDialog>
    <ng-template pTemplate="message" let-message>
        <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
            <i class="pi pi-exclamation-circle text-6xl text-primary-500"></i>
            <p>{{ message.message }}</p>
        </div>
    </ng-template>
</p-confirmDialog>

<app-ssais-r-test-1 *ngIf="editTest && selectedTest.subtest.id === SubtestEnum.SSAISRTest1"
    [testInstance]="selectedTest" (onGoBack)="refresh()" [testee]="selectedTest.testee"></app-ssais-r-test-1>
<app-ssais-r-test-2 *ngIf="editTest && selectedTest.subtest.id === SubtestEnum.SSAISRTest2"
    [testInstance]="selectedTest" (onGoBack)="refresh()" [testee]="selectedTest.testee"></app-ssais-r-test-2>
<app-ssais-r-test-3 *ngIf="editTest && selectedTest.subtest.id === SubtestEnum.SSAISRTest3"
    [testInstance]="selectedTest" (onGoBack)="refresh()" [testee]="selectedTest.testee"></app-ssais-r-test-3>
<app-ssais-r-test-8 *ngIf="editTest && selectedTest.subtest.id === SubtestEnum.SSAISRTest8"
    [testInstance]="selectedTest" (onGoBack)="refresh()" [testee]="selectedTest.testee"></app-ssais-r-test-8>