<div>
    <div>
        <p>You are about to record a test for a testee. Please select the test and subtest:</p>
    </div>
    <div>
        <p-dropdown [options]="tests" [(ngModel)]="selectedTestId" scrollHeight="400px" placeholder="Select Test"
            styleClass="w-15rem mr-3" optionLabel="name" optionValue="id" (onChange)="getSubtests()" appendTo="body"></p-dropdown>
        <p-dropdown [options]="subtests" [(ngModel)]="subtestId" scrollHeight="400px" placeholder="Select Subtest"
            styleClass="w-15rem" optionLabel="name" optionValue="id" appendTo="body"></p-dropdown>
    </div>
    <hr>
    <div>
        <p-button [outlined]="true" label="Cancel" (click)="hideDialog()"></p-button>
        <p-button [raised]="true" label="Start Test" (click)="isFirstOrAfterSixMonths()" [disabled]="subtestId == null" styleClass="mr-2"></p-button>        
    </div>
</div>