<p-card id="testers" [header]="'Testers'" *ngIf="!editTester">
    <p-table 
        #dt1 
        [value]="testers" 
        [paginator]="true" 
        [rows]="10" 
        [rowsPerPageOptions]="[10,25,50]" 
        dataKey="id"
        [showCurrentPageReport]="true" 
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        [globalFilterFields]="[
            'firstName', 
            'lastName', 
            'hpcsaRegisteredProfession',
            'hpcsaRegistrationNumber',
            'activeStatus']" 
        responsiveLayout="scroll"
        styleClass="p-datatable-gridlines" 
        [rowHover]="true">
        <ng-template pTemplate="caption">
            <div class="flex align-items-center justify-content-between">
                <p-button label="Reset" [raised]="true" icon="pi pi-filter-slash" (click)="clear(dt1)"></p-button>
                <span class="p-input-icon-left ml-auto">
                    <i class="pi pi-search"></i>
                    <input #filter pInputText type="text" (input)="onGlobalFilter(dt1,  $event)"
                        placeholder="Search keyword" />
                </span>
            </div>
        </ng-template>
        <ng-template pTemplate="header">
            <tr>
                <th pSortableColumn="firstName">
                    <div>
                        First Name
                        <p-sortIcon field="firstName"></p-sortIcon>
                        <p-columnFilter type="text" field="firstName" display="menu"
                            placeholder="First Name"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="lastName">
                    <div>
                        Last Name
                        <p-sortIcon field="lastName"></p-sortIcon>
                        <p-columnFilter type="text" field="lastName" display="menu"
                            placeholder="Last Name"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="hpcsaRegisteredProfession">
                    <div>
                        HPSA Registered Profession
                        <p-sortIcon field="hpcsaRegisteredProfession"></p-sortIcon>
                        <p-columnFilter type="text" field="hpcsaRegisteredProfession" display="menu"
                            placeholder="HPSA Registered Profession"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="hpcsaRegistrationNumber">
                    <div>
                        HPCSA Registration Number
                        <p-sortIcon field="hpcsaRegistrationNumber"></p-sortIcon>
                        <p-columnFilter type="text" field="hpcsaRegistrationNumber" display="menu"
                            placeholder="HPCSA Registration Number"></p-columnFilter>
                    </div>
                </th>
                <th pSortableColumn="activeStatus">
                    <div>
                        Active Status
                        <p-sortIcon field="activeStatus"></p-sortIcon>
                        <p-columnFilter type="text" field="activeStatus" display="menu"
                            placeholder="Active Status"></p-columnFilter>
                    </div>
                </th>
                <th>Actions</th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-tester>
            <tr>
                <td>{{tester.firstName}}</td>
                <td>{{tester.lastName}}</td>
                <td>{{tester.hpcsaRegisteredProfession}}</td>
                <td>{{tester.hpcsaRegistrationNumber}}</td>
                <td>{{tester.activeStatus}}</td>
                <td>
                    <p-button [rounded]="true" [raised]="true" icon="pi pi-pencil" class="mr-2"
                        (click)="edit(tester)" pTooltip="Edit Tester" tooltipPosition="top"></p-button>
                </td>
            </tr>
        </ng-template>
    </p-table>
</p-card>

<app-tester-edit *ngIf="editTester" [tester]="selectedTester" (onGoBack)="refresh()"></app-tester-edit>