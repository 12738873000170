<p-card id="testee" [style]="{'width': '1200px'}">
    <span style="float: right; cursor: pointer; color: purple;" (click)="goBack()"><i class="pi pi-times"></i></span>
    <h3>Testee Biographic Information / <span class="afrikaans">Toetsling Biografiese Inligting</span></h3>
    <form #profileForm="ngForm" id="entityEditForm" (ngSubmit)="submit()" *ngIf="testee">
        <div class="formgrid grid">
            <div class="field col-4">
                <label for="nameAndSurname" class="required">Name and Surname¹ / <span class="afrikaans">Naam en
                        Van¹:</span> </label>
                <input id="nameAndSurname" name="nameAndSurname" #nameAndSurname="ngModel" type="text"
                    [(ngModel)]="testee.nameAndSurname" [required]="true" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <small style="font-style: italic;"><span class="englishNote"><span
                            style="text-decoration: underline;">¹Confidentiality</span>:
                        Learner’s personal information will be kept confidential /</span><br><span
                        class="afrikaansNote"><span style="text-decoration: underline;">¹Vertroulikheid</span>:
                        Leerder se persoonlike inligting sal
                        konfidensieel gehou word.</span></small>
            </div>
            <div class="field col-4">
                <label for="dateOfBirth" class="required">Date of Birth /
                    <span class="afrikaans">Geboortedatum</span> (yy<span class="afrikaans">jj</span>/m<span
                        class="afrikaans">m</span>/d<span class="afrikaans">d</span>): </label>
                <p-calendar id="dateOfBirth" name="dateOfBirth" [showIcon]="true" inputId="icon"
                    (onClose)="changeDateOfBirth()" [(ngModel)]="testee.dateOfBirth" #dateOfBirth="ngModel"
                    dateFormat="yy/mm/dd" [required]="true">
                </p-calendar>
            </div>
            <div class="field col-4">
                <label for="ageYears" class="mb-3">Age / <span class="afrikaans">Ouderdom:</span> </label>
                <div class="grid">
                    <div class="field col-6">
                        <p-inputGroup>
                            <input id="ageYears" name="ageYears" #ageYears="ngModel" type="text" disabled
                                [(ngModel)]="testee.ageYears" [required]="true" pInputText
                                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                            <p-inputGroupAddon>
                                years
                            </p-inputGroupAddon>
                        </p-inputGroup>
                    </div>
                    <div class="field col-6">
                        <p-inputGroup>
                            <input id="ageMonths" name="ageMonths" #ageMonths="ngModel" type="text" disabled
                                [(ngModel)]="testee.ageMonths" [required]="true" pInputText
                                class="text-base text- color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                            <p-inputGroupAddon>
                                month(s)
                            </p-inputGroupAddon>
                        </p-inputGroup>
                    </div>
                </div>
            </div>
            <div class="field col-8">
                <label for="gender" class="mb-3 required">Gender / <span class="afrikaans">Geslag:</span> </label>
                <div class="grid">
                    <div *ngFor="let gender of genders" class="field col-3">
                        <p-radioButton [inputId]="gender.id" name="gender" [value]="gender.id"
                            [(ngModel)]="testee.genderIdString"></p-radioButton>
                        <label [for]="gender.id" class="ml-2">{{ gender.name }}</label>
                    </div>
                    <div class="field col-2">
                        <input id="otherGender" name="otherGender" type="text"
                            [required]="testee.genderIdString === '3'" [disabled]="testee.genderIdString !== '3'"
                            [(ngModel)]="testee.otherGender" pInputText
                            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>
                </div>
            </div>
            <div class="field col-12">
                <label for="race" class="mb-3 required">Race / <span class="afrikaans">Ras:</span> </label>
                <div class="grid">
                    <div class="field col-3">
                        <p-radioButton [inputId]="'1'" name="race" [value]="1"
                            [(ngModel)]="testee.raceIdString"></p-radioButton>
                        <label [for]="'1'" class="ml-2">African / <span class="afrikaans">Afrikaan</span></label>
                    </div>
                    <div class="field col-3">
                        <p-radioButton [inputId]="'2'" name="race" [value]="2"
                            [(ngModel)]="testee.raceIdString"></p-radioButton>
                        <label [for]="'2'" class="ml-2">Caucasian / <span class="afrikaans">Blank</span></label>
                    </div>
                    <div class="field col-3">
                        <p-radioButton [inputId]="'3'" name="race" [value]="3"
                            [(ngModel)]="testee.raceIdString"></p-radioButton>
                        <label [for]="'3'" class="ml-2">Coloured / <span class="afrikaans">Bruin</span></label>
                    </div>
                    <div class="field col-3">
                        <p-radioButton [inputId]="'4'" name="race" [value]="4"
                            [(ngModel)]="testee.raceIdString"></p-radioButton>
                        <label [for]="'4'" class="ml-2">Indian / <span class="afrikaans">Indiër</span></label>
                    </div>
                    <div class="field col-3">
                        <p-radioButton [inputId]="'5'" name="race" [value]="5"
                            [(ngModel)]="testee.raceIdString"></p-radioButton>
                        <label [for]="'5'" class="ml-2">Asian / <span class="afrikaans">Asiër</span></label>
                    </div>
                    <div class="field col-3">
                        <p-radioButton [inputId]="'6'" name="race" [value]="6"
                            [(ngModel)]="testee.raceIdString"></p-radioButton>
                        <label [for]="'6'" class="ml-2">Other / <span class="afrikaans">Ander</span></label>
                    </div>
                    <div class="field col-2">
                        <input id="otherRace" name="otherRace" type="text" [required]="testee.raceIdString === '6'"
                            [disabled]="testee.raceIdString !== '6'" [(ngModel)]="testee.otherRace" pInputText
                            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>
                </div>
            </div>
            <div class="field col-4">
                <label for="ethnicity" class="required">Ethnicity (e.g. Zulu, Ndebele, Caucasian) / <br> <span
                        class="afrikaans">Etnisiteit (e.g./bv. Zulu, Ndebele, Blanke):</span></label>
                <input id="ethnicity" name="ethnicity" #ethnicity="ngModel" type="text" [(ngModel)]="testee.ethnicity"
                    [required]="true" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>
            <div class="field col-4">
                <label for="firstLanguage" class="required" style="margin-bottom: 29px;">First Language / <span
                        class="afrikaans">Eerste Taal:</span></label>
                <input id="firstLanguage" name="firstLanguage" #firstLanguage="ngModel" type="text"
                    [(ngModel)]="testee.firstLanguage" [required]="true" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>
            <div class="field col-2">
                <label for="otherLanguage1">Additional Language 1 / <span class="afrikaans">Addisionele Taal
                        1:</span></label>
                <input id="otherLanguage1" name="otherLanguage1" #otherLanguage1="ngModel" type="text"
                    [(ngModel)]="testee.otherLanguage1" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>
            <div class="field col-2">
                <label for="otherLanguage2">Additional Language 2 / <span class="afrikaans">Additionele Taal
                        2:</span></label>
                <input id="otherLanguage2" name="otherLanguage2" #otherLanguage2="ngModel" type="text"
                    [(ngModel)]="testee.otherLanguage2" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>
            <div class="field col-2">
                <label for="otherLanguage3">Additional Language 3 / <span class="afrikaans">Additionele Taal
                        3:</span></label>
                <input id="otherLanguage3" name="otherLanguage3" #otherLanguage3="ngModel" type="text"
                    [(ngModel)]="testee.otherLanguage3" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>            
        </div>
        <label for="school" class="required">School / Skool:</label>    
        <div class="formgrid grid">            
            <div class="field col-3">
                <label style="margin-bottom: 29px;">School Name / <span class="afrikaans">Skoolnaam</span> </label>
                <p-autoComplete id="school" name="school" #school="ngModel" [(ngModel)]="selectedSchool"
                    [dropdown]="true" [suggestions]="filteredSchools" (completeMethod)="filterSchool($event)"
                    field="name" scrollHeight="600px" />
            </div>
            <div class="field col-3">
                <label for="grade" class="required" style="margin-bottom: 29px;">School Grade / <span class="afrikaans">Skool Graad</span> </label>
                <input id="grade" name="grade" #grade="ngModel" type="number" [(ngModel)]="testee.grade"
                    [required]="true" min="1" max="12" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>
            <div class="field col-3">
                <label for="province" class="required">School's Province / <span class="afrikaans">Skool se Provinsie</span> </label>
                <input id="province" name="province" #province="ngModel" type="text" [(ngModel)]="testee.province"
                    [required]="true" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>            
            <div class="field col-3">
                <label for="schoolType" class="required" style="margin-bottom: 29px;">Type² of School / <span class="afrikaans">Tipe² Skool:</span>
                </label>
                <input id="schoolType" name="schoolType" #schoolType="ngModel" type="text"
                    [(ngModel)]="testee.schoolType" [required]="true" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <small style="font-style: italic;"><span class="englishNote"><span
                            style="text-decoration: underline;">²Type</span>: e.g. mainstream, public school, private
                        school,
                        home school, LSEN, etc. / <br></span><span class="afrikaansNote"><span
                            style="text-decoration: underline;">²Tipe</span>:
                        bv. hoofstroom, publieke skool, privaatskool, tuisonderrig, spesiale onderrig,
                        ens.</span></small>
            </div>
            <div class="field col-6">
                <label for="languageOfInstruction" class="required" style="margin-bottom: 29px;">Language of Instruction (at school) / <span
                        class="afrikaans">Taal van Onderrig (by skool)</span>
                </label>
                <input id="languageOfInstruction" name="languageOfInstruction" #languageOfInstruction="ngModel"
                    type="text" [(ngModel)]="testee.languageOfInstruction" [required]="true" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>
            <div class="field col-6">
                <label for="schoolStatusId" class="mb-3 required">School Socio-Economic Status (SES) /<br> <span
                        class="afrikaans">Skool Sosio-Ekonomiese Status (SES)</span> </label>
                <div class="grid">
                    <div class="field col-4">
                        <p-radioButton name="schoolStatusId" [value]="1" [(ngModel)]="testee.schoolStatusId"
                            [style]="{'margin-top': '8px'}" inputId="higher"></p-radioButton>
                        <label for="higher" class="ml-2 mb-0">Higher / <span class="afrikaans">Hoër</span></label>
                    </div>
                    <div class="field col-3">
                        <p-radioButton name="schoolStatusId" [value]="2" [(ngModel)]="testee.schoolStatusId"
                            inputId="laer" [style]="{'margin-top': '8px'}"></p-radioButton>
                        <label for="laer" class="ml-2 mb-0">Lower / <span class="afrikaans">Laer</span></label>
                    </div>
                </div>
            </div>
            <div class="field col-6">
                <label for="SchoolQuintile">School Quintile³ (Q) / <span class="afrikaans">Skool Kwintiel³ (K): Q/K (1 -
                        5)</span>
                </label>
                <input id="SchoolQuintile" name="SchoolQuintile" #SchoolQuintile="ngModel" type="number"
                    [(ngModel)]="testee.schoolQuintile" min="1" max="5" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                <small style="font-style: italic;"><span class="englishNote"><span
                            style="text-decoration: underline;">³Quintile</span>: <br>
                        <span class="bold">Quintile 1, 2 & 3</span> = Lower Socio-Economic Status (SES), Rural Schools
                        <br>
                        <span class="bold">Quintile 4 & 5</span> = Higher SES, Public and Private Schools / <br></span>
                    <span class="afrikaansNote"><span style="text-decoration: underline;">³Kwintiel</span>: <br>
                        <span class="bold">Kwintiel 1, 2 & 3</span> = Laer Sosio-Ekonomiese Status (SES), Landelike
                        Skole <br>
                        <span class="bold">Kwintiel 4 & 5</span> = Hoër SES, Publieke en Private Skole. <br></span>
                </small>
            </div>
            <div class="field col-12">
                <Label>Father or Guardian 1 / <span class="afrikaans">Vader of Voog 1:</span> </Label>
                <div class="formgrid grid">
                    <div class="field col">
                        <Label for="fatherOrGaurdian1Occupation">Occupation / <span class="afrikaans">Beroep</span>
                        </Label>
                        <input id="fatherOrGaurdian1Occupation" name="fatherOrGaurdian1Occupation"
                            #fatherOrGaurdian1Occupation="ngModel" type="text" pInputText
                            [(ngModel)]="testee.fatherOrGaurdian1Occupation"
                            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>
                    <div class="field col">
                        <Label for="fatherOrGaurdian1Qualification">Highest Academic Qualification / <span
                                class="afrikaans">Hoogste Akademiese Kwalifikasie</span> </Label>
                        <input id="fatherOrGaurdian1Qualification" name="fatherOrGaurdian1Qualification"
                            #fatherOrGaurdian1Qualification="ngModel" type="text" pInputText
                            [(ngModel)]="testee.fatherOrGaurdian1Qualification"
                            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>
                </div>
            </div>
            <div class="field col-12">
                <Label>Mother or Guardian 2 / <span class="afrikaans">Moeder of Voog 2:</span> </Label>
                <div class="formgrid grid">
                    <div class="field col">
                        <Label for="motherOrGaurdian2Occupation">Occupation / <span class="afrikaans">Beroep</span>
                        </Label>
                        <input id="motherOrGaurdian2Occupation" name="motherOrGaurdian2Occupation"
                            #motherOrGaurdian2Occupation="ngModel" type="text"
                            [(ngModel)]="testee.motherOrGaurdian2Occupation" pInputText
                            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>
                    <div class="field col">
                        <Label for="motherOrGaurdian2Qualification">Highest Academic Qualification / <span
                                class="afrikaans">Hoogste Akademiese Kwalifikasie</span> </Label>
                        <input id="motherOrGaurdian2Qualification" name="motherOrGaurdian2Qualification"
                            #motherOrGaurdian2Qualification="ngModel" type="text"
                            [(ngModel)]="testee.motherOrGaurdian2Qualification" pInputText
                            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    </div>
                </div>
            </div>
            <div class="field col-6">
                <label for="testerNameAndSurname" class="required">Tester / <span class="afrikaans">Toetsafnemer:</span><br>Name and Surname / <span
                        class="afrikaans">Naam en Van</span>
                </label>
                <input id="testerNameAndSurname" name="testerNameAndSurname" #testerNameAndSurname="ngModel" type="text"
                    [(ngModel)]="testee.testerNameAndSurname" [required]="true" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>
            <div class="field col-6">
                <label for="hpcsaRegisteredProfession" class="required">HPCSA Registered Profession / 
                    <span class="afrikaans">HPCSA Registreerde Professie:</span><br>(e.g. Educational Psychologist)   <span class="afrikaans">(bv. Opvoedkundige Sielkundige)</span>     
                </label>
                <input id="hpcsaRegisteredProfession" name="hpcsaRegisteredProfession"
                    #hpcsaRegisteredProfession="ngModel" type="text" [(ngModel)]="testee.hpcsaRegisteredProfession"
                    [required]="true" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>
            <div class="field col-4">
                <label for="testingLanguage" class="required" style="margin-bottom: 29px;">Language of Testing / <span class="afrikaans">Taal van
                        Toetsing:</span> </label>
                <div class="grid">
                    <div class="field col-4">
                        <p-radioButton name="testingLanguage" [value]="1" [(ngModel)]="testee.testingLanguage"
                            [style]="{'margin-top': '8px'}" inputId="testingLanguage"></p-radioButton>
                        <label for="testingLanguage" class="ml-2 mb-0">English</label>
                    </div>
                    <div class="field col-4">
                        <p-radioButton name="testingLanguage" [value]="2" [(ngModel)]="testee.testingLanguage"
                            inputId="testingLanguage" [style]="{'margin-top': '8px'}"></p-radioButton>
                        <label for="testingLanguage" class="ml-2 mb-0">Afrikaans</label>
                    </div>
                    <div class="field col-4">
                        <p-radioButton name="testingLanguage" [value]="3" [(ngModel)]="testee.testingLanguage"
                            inputId="testingLanguage" [style]="{'margin-top': '8px'}"></p-radioButton>
                        <label for="testingLanguage" class="ml-2 mb-0">Both</label>
                    </div>
                </div>
            </div>
            <div class="field col-12">
                <label for="challenges">Any Testee Challenges (e.g. difficulties in learning, physical, medical, social) /<br> <span
                        class="afrikaans">Enige Toetsling Uitdagings (bv. uitdagings in leer, fisiese, mediese, Maatskaplike):</span>
                </label>
                <input id="challenges" name="challenges" #challenges="ngModel" type="text"
                    [(ngModel)]="testee.challenges" pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>
            <div class="field col-12">
                <label for="comments">Any Additional Comments / <span class="afrikaans">Enige Addisionele
                        Opmerkings:</span>
                </label>
                <input id="comments" name="comments" #comments="ngModel" type="text" [(ngModel)]="testee.comments"
                    pInputText
                    class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            </div>
            <div class="field col-12">
                <p-button type="submit" [raised]="true" class="mr-2" label="Submit"
                    [disabled]="profileForm.invalid"></p-button>
                <p-button type="button" [outlined]="true" [label]="backButton" (onClick)="goBack()"></p-button>
            </div>
        </div>
    </form>
</p-card>