export enum Card
{
    A1 = 1,
    B1 = 2,
    A2 = 3,
    B2 = 4,
    A3 = 5,
    B3 = 6,
    A4 = 7,
    B4 = 8,
    A5 = 9,
    B5 = 10,
    A = 11,
    B = 12
}