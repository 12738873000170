<p-toast [life]="10000"></p-toast>

<div class="px-5 min-h-screen flex justify-content-center align-items-center">
    <div class="border-1 surface-border surface-card border-round py-7 px-4 md:px-7 z-1">
        <div class="mb-4">
            <img src="../../../assets/images/MM2 Login.jpg">
        </div>
        <div class="mb-4">
            <div class="text-900 text-xl font-bold mb-2">Login</div>
            <span class="text-600 font-medium">Please enter your details</span>
        </div>
        <div class="flex flex-column">
            <span class="p-input-icon-left w-full mb-4">
                <i class="pi pi-envelope"></i>
                <input id="email" type="text" pInputText class="w-full" placeholder="Email"
                    [(ngModel)]="model.userName">
            </span>
            <span class="p-input-icon-left w-full mb-4">
                <i class="pi pi-lock"></i>
                <input id="password" type="password" pInputText class="w-full" placeholder="Password"
                    [(ngModel)]="model.password">
            </span>
            <div class="flex-container">
                <p-button [raised]="true" label="Login" class="w-auto mr-2" (click)="login()"></p-button>
                <p-button [raised]="true" severity="info" label="Register" class="w-auto" (click)="register()"></p-button>
                <a routerLink="/forgot-password"
                    class="text-600 cursor-pointer text-blue-500 hover:text-purple-500 cursor-pointer ml-auto transition-colors transition-duration-300"
                    style="float: right;">Forgot
                    password</a>
            </div>
        </div>
    </div>
</div>