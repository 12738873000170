import { TestInstanceDTO } from "./test-instance.dto";
import { QuestionDTO } from "./question.dto";

export class AnswerDTO {
    id: number;
    testInstanceId: number;
    questionId: number;
    response: string;
    time?: number;
    score: number;
    //testInstance: TestInstanceDTO | null;
    
    //question: QuestionDTO | null;
}