import { Component, OnInit } from '@angular/core';
import { ToolbarModule } from 'primeng/toolbar';
import { AvatarModule } from 'primeng/avatar';
import { InputTextModule } from 'primeng/inputtext';
import { CommonModule } from '@angular/common';
import { MenuModule } from 'primeng/menu';
import { MenuItem, MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { AuthService } from '../../shared/services/auth.service';
import { IsAdminDirective } from '../../shared/directives/is-admin.directive';
import { TesteeService } from '../../shared/services/testee.service';
import { Constants } from '../../shared/constants';
import { ToastModule } from 'primeng/toast';
import { TestService } from '../../shared/services/test.service';
import { UserDTO } from '../../shared/dtos/user.dto';
import { TesterService } from '../../shared/services/tester.service';
import { ApiResponse } from '../../shared/models/apiResponse';

@Component({
  selector: 'app-topbar',
  standalone: true,
  imports: [
    ToolbarModule,
    CommonModule,
    MenuModule,
    IsAdminDirective
  ],
  templateUrl: './topbar.component.html',
  styleUrl: './topbar.component.scss'
})
export class TopbarComponent implements OnInit {
  items: MenuItem[];  
  
  loggedInUser: UserDTO;

  constructor(private router: Router,
    private authService: AuthService,
    private testerService: TesterService,
    private testeesService: TesteeService,
    private testService: TestService
  ) {
    this.items = [
      {
        label: 'Profile',
        icon: 'pi pi-user',
        command: () => {
          this.router.navigate(['/app/profile']);
        }
      },
      {
        label: 'Logout',
        icon: 'pi pi-power-off',
        command: () => {
          this.authService.logout();
        }
      }
    ];
  }

  ngOnInit() {
    this.testerService.getLoggedInTester().subscribe((response: ApiResponse) => {
      this.loggedInUser = response.result;
    });
  }
  
  refreshTesteePage() {
    this.testeesService.refreshTestees.next(null);
  }

  refreshTestPage() {
    this.testService.refreshTests.next(null);
  }
}
