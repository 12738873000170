import { SubtestDTO } from "./subtest.dto";

export class QuestionDTO {
    id: number;
    subtestId: number;
    card: number;
    number: string;
    item: string;
    itemAfrikaans: string;
    answer: string;
    timeLimit: number;
    languageId: number;
    extra: string;
    response: string;
    time: number;
    score: number;
    answerId: number;
}