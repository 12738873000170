import { catchError, throwError } from 'rxjs';
import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { ToastrService } from '../services/toastr.service';
import { inject } from '@angular/core';

export const ErrorInterceptor: HttpInterceptorFn = (req, next) => {
    const toastrService = inject(ToastrService);
    
    return next(req).pipe(
        catchError((error: HttpErrorResponse) => {

            toastrService.showToastrError(error);
            console.log(error);

            return throwError(() => error);
        })
    );;
};