import { AnswerDTO } from "./answer.dto";
import { SubtestDTO } from "./subtest.dto";
import { TesteeDTO } from "./testee.dto";

export class TestInstanceDTO {
    id: number;
    subtestId: number;
    testeeId: number;
    comments: string;
    dateOfTesting: Date;
    dateOfTestingString: string;
    subtestName: string;

    answers: AnswerDTO[];
    subtest: SubtestDTO;
    testee: TesteeDTO;
}