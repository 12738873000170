<p-toast [life]="10000"></p-toast>

<div class="px-5 min-h-screen flex justify-content-center align-items-center mt-5">
    <div class="border-1 surface-border surface-card border-round pb-4 px-4 md:px-7 z-1 w-8"
        *ngIf="!registrationSubmitted">
        <h1>Register</h1>
        <form #registerForm="ngForm" (ngSubmit)="submit()">
            <div class="formgrid grid">
                <div class="field col">
                    <label for="userName" class="required">User Name (Email)</label>
                    <input id="userName" name="userName" #userName="ngModel" email pInputText
                        [(ngModel)]="model.userName" required
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full" />
                    <small *ngIf="userName.invalid && userName.touched">
                        Username is required.
                    </small>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col">
                    <label for="firstname" class="required">First Name</label>
                    <input id="firstname" type="text" [(ngModel)]="model.firstName" name="firstName" required
                        #firstName="ngModel"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    <small *ngIf="firstName.invalid && firstName.touched">
                        First Name is required.
                    </small>
                </div>
                <div class="field col">
                    <label for="lastname" class="required">Last Name</label>
                    <input id="lastname" type="text" [(ngModel)]="model.lastName" name="lastName" required
                        #lastName="ngModel"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    <small *ngIf="lastName.invalid && lastName.touched">
                        Last Name is required.
                    </small>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col">
                    <label for="hpcsaRegisteredProfession" class="required">HPCSA Registered Profession</label>
                    <input id="hpcsaRegisteredProfession" type="text" [(ngModel)]="model.hpcsaRegisteredProfession"
                        name="hpcsaRegisteredProfession" required #hpcsaRegisteredProfession="ngModel"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    <small *ngIf="hpcsaRegisteredProfession.invalid && hpcsaRegisteredProfession.touched">
                        HPCSA Registered Profession is required.
                    </small>
                </div>
                <div class="field col">
                    <label for="hpcsaRegistrationNumber" class="required">HPCSA Registration Number</label>
                    <input id="hpcsaRegistrationNumber" type="text" [(ngModel)]="model.hpcsaRegistrationNumber"
                        name="hpcsaRegistrationNumber" required #hpcsaRegistrationNumber="ngModel"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    <small *ngIf="hpcsaRegistrationNumber.invalid && hpcsaRegistrationNumber.touched">
                        HPCSA Registration Number is required.
                    </small>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col">
                    <label for="idNumber" class="required">Identity Number</label>
                    <input id="idNumber" type="text" [(ngModel)]="model.idNumber" name="idNumber" required
                        #idNumber="ngModel"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    <small *ngIf="idNumber.invalid && idNumber.touched">
                        Identity Number is required.
                    </small>
                </div>
                <div class="field col">
                    <label for="practice" class="required">Practice Number</label>
                    <input id="practice" type="text" [(ngModel)]="model.practice" name="practice" required
                        #practice="ngModel"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    <small *ngIf="practice.invalid && practice.touched">
                        Practice is required.
                    </small>
                </div>
            </div>
            <div class="formgrid grid">
                <div class="field col-4">
                    <label for="telHome">Tel Home</label>
                    <input id="telHome" type="text" [(ngModel)]="model.telHome" name="telHome" #telHome="ngModel"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                </div>
                <div class="field col-4">
                    <label for="telWork">Tel Work</label>
                    <input id="telWork" type="text" [(ngModel)]="model.telWork" name="telWork" #telWork="ngModel"
                        class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                </div>
                <div class="field col-4">
                    <label for="language" class="required">Language</label>
                    <p-dropdown id="language" [options]="languages" optionLabel="label" optionValue="value"
                        [(ngModel)]="model.languageId" name="language" required #language="ngModel" scrollHeight="450px"
                        styleClass="mr-3 w-full">
                    </p-dropdown>
                    <small *ngIf="language.invalid && language.touched">
                        Language is required.
                    </small>
                </div>
            </div>
            <h5>Physical Address</h5>
            <div class="flex-container mb-3">
                <div>
                    <label for="street" class="required">Street</label>
                    <input id="street" type="text" [(ngModel)]="model.address.street" name="street" required
                        #street="ngModel"
                        class="text-base text-color surface-overlay p-2 mt-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    <small *ngIf="street.invalid && street.touched">
                        Street is required.
                    </small>
                </div>
                <div>
                    <label for="suburb" class="required">Suburb</label>
                    <input id="suburb" type="text" [(ngModel)]="model.address.suburb" name="suburb" required
                        #suburb="ngModel"
                        class="text-base text-color surface-overlay p-2 mt-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    <small *ngIf="suburb.invalid && suburb.touched">
                        Suburb is required.
                    </small>
                </div>
                <div>
                    <label for="city" class="required">City</label>
                    <input id="city" type="text" [(ngModel)]="model.address.city" name="city" required #city="ngModel"
                        class="text-base text-color surface-overlay p-2 mt-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    <small *ngIf="city.invalid && city.touched">
                        City is required.
                    </small>
                </div>
                <div>
                    <label for="postalCode" class="required">Postal Code</label>
                    <input id="postalCode" type="text" [(ngModel)]="model.address.postalCode" name="postalCode" required
                        #postalCode="ngModel"
                        class="text-base text-color surface-overlay p-2 mt-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    <small *ngIf="postalCode.invalid && postalCode.touched">
                        Postal Code is required.
                    </small>
                </div>
                <div>
                    <label for="province" class="required">Province</label>
                    <p-dropdown id="province" [options]="provinces" optionLabel="label" optionValue="value"
                        [(ngModel)]="model.address.provinceId" name="province" required #province="ngModel"
                        scrollHeight="400px" styleClass="mt-2 mr-3 w-full">
                    </p-dropdown>
                    <small *ngIf="province.invalid && province.touched">
                        Province is required.
                    </small>
                </div>
                <div>
                    <label for="country" class="required">Country</label>
                    <input id="country" type="text" [(ngModel)]="model.address.country" name="country" required
                        #country="ngModel"
                        class="text-base text-color surface-overlay p-2 mt-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                    <small *ngIf="country.invalid && country.touched">
                        Country required.
                    </small>
                </div>
                <div class="field">
                    <label for="password" class="required">Password <i class="pi pi-info-circle"
                            [pTooltip]="passwordInfo"></i></label>
                    <span class="p-input-icon-right block" *ngIf="!showPassword">
                        <i class="pi pi-eye" (click)="togglePasswordVisibility()"></i>
                        <input [type]="passwordInputType" id="password" name="password" [(ngModel)]="model.password"
                            #password="ngModel" required
                            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        <small *ngIf="password.invalid && password.touched">
                            Password is required.
                        </small>
                    </span>
                    <span class="p-input-icon-right block" *ngIf="showPassword">
                        <i class="pi pi-eye-slash" (click)="togglePasswordVisibility()"></i>
                        <input [type]="passwordInputType" id="password" name="password" [(ngModel)]="model.password"
                            #password="ngModel" required
                            class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
                        <small *ngIf="password.invalid && password.touched">
                            Password is required.
                        </small>
                    </span>
                </div>
            </div>
            <p-button type="submit" [raised]="true" label="Submit" [disabled]="registerForm.invalid"
                styleClass="mr-2"></p-button>
            <p-button [outlined]="true" routerLink="/login">Back</p-button>
        </form>
    </div>
    <div class="border-1 surface-border surface-card border-round py-7 px-4 md:px-7 z-1" *ngIf="registrationSubmitted">
        <h1>Registration successfully submitted</h1>
        <p>An email has been sent to {{model.userName}}. Please click on the "Verify email" link in the email to verify
            your email
            address. The link will expire in 15 minutes.</p>
        <p-button [raised]="true" routerLink="/login">Back to Login</p-button>
    </div>
</div>