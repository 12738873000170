<div class="card1">
    <p-card header="Download Research Data">
        <form #excelFilterForm="ngForm">
            <div class="formgrid grid">
                <div class="field col-6">
                    <label for="school" class="required">School</label>
                    <p-dropdown id="school" name="school" #school="ngModel" [(ngModel)]="selectedSchoolId"
                        [options]="schools" optionLabel="name" optionValue="id" scrollHeight="600px"
                        placeholder="Select a School" (onChange)="getTestees($event)"
                        styleClass="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                        [style]="{'appearance': 'auto'}" required></p-dropdown>
                    <small *ngIf="school.invalid && school.touched">
                        School is required.
                    </small>
                </div>
                <div class="field col-3">
                    <label for="from" class="required">From Year (testing date)</label>
                    <p-dropdown id="from" name="from" #from="ngModel" [(ngModel)]="fromYear" [options]="fromYears"
                        scrollHeight="600px" (onChange)="getTestees($event)"
                        styleClass="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                        [style]="{'appearance': 'auto'}" required></p-dropdown>
                    <small *ngIf="from.invalid && from.touched">
                        From Year is required.
                    </small>
                </div>
                <div class="field col-3">
                    <label for="to" class="required">To Year (testing date)</label>
                    <p-dropdown id="to" name="to" #to="ngModel" [(ngModel)]="toYear" [options]="toYears"
                        scrollHeight="600px" (onChange)="getTestees($event)"
                        styleClass="w-full text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round outline-none focus:border-primary"
                        [style]="{'appearance': 'auto'}" required></p-dropdown>
                    <small *ngIf="to.invalid && to.touched">
                        To Year is required.
                    </small>
                </div>
            </div>
            <div class="p-fluid p-formGrid grid">
                <div class="field col">
                    <label for="start" class="required">Start Age</label>
                    <p-inputNumber inputId="start" name="start" #start="ngModel" [(ngModel)]="startAge" pInputNumber
                        [min]="5" [max]="80" required [showButtons]="true" styleClass="mt-2" />
                    <small *ngIf="start.invalid && start.touched">
                        Start Age is required.
                    </small>
                </div>
                <div class="field col">
                    <label for="end" class="required">End Age</label>
                    <p-inputNumber inputId="end" name="end" #end="ngModel" [(ngModel)]="endAge" pInputNumber [min]="5"
                        [max]="80" required [showButtons]="true" styleClass="mt-2" />
                    <small *ngIf="end.invalid && end.touched">
                        End Age is required.
                    </small>
                </div>
            </div>
            <p-button label="Download Testees" icon="pi pi-download" (click)="downloadTestees()" class="mr-3"
                [disabled]="excelFilterForm.invalid || endAge < startAge || toYear < fromYear"></p-button>
            <p-button label="Download SSAIS-R Results" icon="pi pi-download" (click)="downloadSsais_R()"
                [disabled]="excelFilterForm.invalid || endAge < startAge || toYear < fromYear"></p-button>
        </form>
    </p-card>
</div>

<div class="card2">
    <p-card header="Testees">
        <p-table #dt1 [value]="testees" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,25,50]" dataKey="id"
            [showCurrentPageReport]="true"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [globalFilterFields]="[
            'id', 
            'nameAndSurname', 
            'dateOfBirthString',
            'ageYears',
            'genderIdString',
            'raceIdString',
            'ethnicity',
            'firstLanguage',
            'otherLanguage1',
            'otherLanguage2',
            'otherLanguage3',
            'testingLanguageString',
            'schoolName',
            'grade',
            'schoolType',
            'province',
            'languageOfInstruction',
            'schoolStatusIdString',
            'schoolQuintile',
            'fatherOrGaurdian1Occupation',
            'fatherOrGaurdian1Qualification',
            'motherOrGaurdian2Occupation',
            'motherOrGaurdian2Qualification',
            'testerNameAndSurname',
            'hpcsaRegisteredProfession',
            'hpcsaRegistrationNumber',
            'challenges',
            'comments'
            ]" responsiveLayout="scroll" styleClass="p-datatable-gridlines" [rowHover]="true">
            <ng-template pTemplate="caption">
                <div class="flex align-items-center justify-content-between">
                    <p-button label="Reset" [raised]="true" icon="pi pi-filter-slash" (click)="clear(dt1)"></p-button>
                    <span class="p-input-icon-left ml-auto">
                        <i class="pi pi-search"></i>
                        <input #filter pInputText type="text" (input)="onGlobalFilter(dt1,  $event)"
                            placeholder="Search keyword" />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="id">
                        <div>
                            Nr.
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="id"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="id" display="menu" placeholder="Nr."></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="nameAndSurname">
                        <div>
                            Name and Surname
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="nameAndSurname"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="nameAndSurname" display="menu"
                                placeholder="Name and Surname"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="dateOfBirthString">
                        <div>
                            Date of Birth
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="dateOfBirthString"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="dateOfBirthString" display="menu"
                                placeholder="Date of Birth"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="ageYears">
                        <div>
                            Learner Age
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="ageYears"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="ageYears" display="menu"
                                placeholder="Learner Age"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="genderIdString">
                        <div>
                            Gender
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="genderIdString"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="genderIdString" display="menu"
                                placeholder="Gender"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="raceIdString">
                        <div>
                            Race
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="raceIdString"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="raceIdString" display="menu"
                                placeholder="Race"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="ethnicity">
                        <div>
                            Ethnicity
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="ethnicity"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="ethnicity" display="menu"
                                placeholder="Ethnicity"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="firstLanguage">
                        <div>
                            First Language
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="firstLanguage"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="firstLanguage" display="menu"
                                placeholder="First Language"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="otherLanguage1">
                        <div>
                            Other Language(s) 1
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="otherLanguage1"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="otherLanguage1" display="menu"
                                placeholder="Other Language(s) 1"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="otherLanguage2">
                        <div>
                            Other Language(s) 2
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="otherLanguage2"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="otherLanguage2" display="menu"
                                placeholder="Other Language(s) 2"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="otherLanguage3">
                        <div>
                            Other Language(s) 3
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="otherLanguage3"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="otherLanguage3" display="menu"
                                placeholder="Other Language(s) 3"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="testingLanguageString">
                        <div>
                            Language of Testing
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="testingLanguageString"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="testingLanguageString" display="menu"
                                placeholder="Language of Testing"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="schoolName">
                        <div>
                            School Name
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="schoolName"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="schoolName" display="menu"
                                placeholder="School Name"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="grade">
                        <div>
                            Grade
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="grade"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="grade" display="menu"
                                placeholder="Grade"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="schoolType">
                        <div>
                            Type of School
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="schoolType"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="schoolType" display="menu"
                                placeholder="Type of School"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="province">
                        <div>
                            Province
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="province"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="province" display="menu"
                                placeholder="Province"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="languageOfInstruction">
                        <div>
                            Lanuage of Instruction
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="languageOfInstruction"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="languageOfInstruction" display="menu"
                                placeholder="Lanuage of Instruction"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="schoolStatusIdString">
                        <div>
                            School Socio-Economic Status (SES)
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="schoolStatusIdString"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="schoolStatusIdString" display="menu"
                                placeholder="School Socio-Economic Status (SES)"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="schoolQuintile">
                        <div>
                            School Quintile
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="schoolQuintile"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="schoolQuintile" display="menu"
                                placeholder="School Quintile"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="fatherOrGaurdian1Occupation">
                        <div>
                            Guardian 1 / Father: Occupation
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="fatherOrGaurdian1Occupation"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="fatherOrGaurdian1Occupation" display="menu"
                                placeholder="Guardian 1 / Father: Occupation"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="fatherOrGaurdian1Qualification">
                        <div>
                            Guardian 1 / Father: Highest Academic Qualification
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="fatherOrGaurdian1Qualification"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="fatherOrGaurdian1Qualification" display="menu"
                                placeholder="Guardian 1 / Father: Highest Academic Qualification"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="motherOrGaurdian2Occupation">
                        <div>
                            Guardian 2 / Mother: Occupation
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="motherOrGaurdian2Occupation"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="motherOrGaurdian2Occupation" display="menu"
                                placeholder="Guardian 2 / Mother: Occupation"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="motherOrGaurdian2Qualification">
                        <div>
                            Guardian 2 / Mother: Highest Academic Qualification
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="motherOrGaurdian2Qualification"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="motherOrGaurdian2Qualification" display="menu"
                                placeholder="Guardian 2 / Mother: Highest Academic Qualification"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="testerNameAndSurname">
                        <div>
                            Tester Name and Surname
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="testerNameAndSurname"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="testerNameAndSurname" display="menu"
                                placeholder="Tester Name and Surname"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="hpcsaRegisteredProfession">
                        <div>
                            HPCSA Registered Profession
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="hpcsaRegisteredProfession"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="hpcsaRegisteredProfession" display="menu"
                                placeholder="HPCSA Registered Profession"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="hpcsaRegistrationNumber">
                        <div>
                            HPCSA Number
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="hpcsaRegistrationNumber"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="hpcsaRegistrationNumber" display="menu"
                                placeholder="HPCSA Number"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="challenges">
                        <div>
                            Any Challenges
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="challenges"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="challenges" display="menu"
                                placeholder="Any Challenges"></p-columnFilter>
                        </div>
                    </th>
                    <th pSortableColumn="comments">
                        <div>
                            Additional Comments
                            <p-sortIcon pTooltip="Sort" tooltipPosition="top" field="comments"></p-sortIcon>
                            <p-columnFilter pTooltip="Filter" tooltipPosition="top" type="text" field="comments" display="menu"
                                placeholder="Additional Comments"></p-columnFilter>
                        </div>
                    </th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-testee>
                <tr>
                    <td>{{testee.id}}</td>
                    <td>{{testee.nameAndSurname}}</td>
                    <td>{{testee.dateOfBirthString}}</td>
                    <td>{{testee.ageYears}}</td>
                    <td>{{testee.genderIdString}}</td>
                    <td>{{testee.raceIdString}}</td>
                    <td>{{testee.ethnicity}}</td>
                    <td>{{testee.firstLanguage}}</td>
                    <td>{{testee.otherLanguage1}}</td>
                    <td>{{testee.otherLanguage2}}</td>
                    <td>{{testee.otherLanguage3}}</td>
                    <td>{{testee.testingLanguageString}}</td>
                    <td>{{testee.schoolName}}</td>
                    <td>{{testee.grade}}</td>
                    <td>{{testee.schoolType}}</td>
                    <td>{{testee.province}}</td>
                    <td>{{testee.languageOfInstruction}}</td>
                    <td>{{testee.schoolStatusIdString}}</td>
                    <td>{{testee.schoolQuintile}}</td>
                    <td>{{testee.fatherOrGaurdian1Occupation}}</td>
                    <td>{{testee.fatherOrGaurdian1Qualification}}</td>
                    <td>{{testee.motherOrGaurdian2Occupation}}</td>
                    <td>{{testee.motherOrGaurdian2Qualification}}</td>
                    <td>{{testee.testerNameAndSurname}}</td>
                    <td>{{testee.hpcsaRegisteredProfession}}</td>
                    <td>{{testee.hpcsaRegistrationNumber}}</td>
                    <td>{{testee.challenges}}</td>
                    <td>{{testee.comments}}</td>
                </tr>
            </ng-template>
        </p-table>
    </p-card>
</div>