import { Component, OnInit } from '@angular/core';
import { TopbarComponent } from './topbar/topbar.component';
import { ToastModule } from 'primeng/toast';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-main',
  standalone: true,
  imports: [
    TopbarComponent,
    ToastModule,
    RouterOutlet
  ],
  templateUrl: './main.component.html',
  styleUrl: './main.component.scss'
})
export class MainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    document.body.style.backgroundImage = "url('../../assets/images/MM2 Background.jpg')";
    document.body.style.backgroundRepeat = "repeat";
    document.body.style.backgroundSize = "auto";
    document.body.style.backgroundPosition = "0% 0%";
    document.body.style.height = "100%";
  }
}
