import { HttpErrorResponse } from '@angular/common/http';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { MessageService } from 'primeng/api';
import { QuestionDTO } from '../../../../shared/dtos/question.dto';
import { ApiResponse } from '../../../../shared/models/apiResponse';
import { TestService } from '../../../../shared/services/test.service';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { InputTextModule } from 'primeng/inputtext';
import { TestInstanceDTO } from '../../../../shared/dtos/test-instance.dto';
import { CardModule } from 'primeng/card';
import { AnswerDTO } from '../../../../shared/dtos/answer.dto';
import { DateService } from '../../../../shared/services/date.service';
import { RouterLink } from '@angular/router';
import { Card } from '../../../../shared/enums/card.enum';
import { Subtest } from '../../../../shared/enums/subtest.enum';
import { TesteeDTO } from '../../../../shared/dtos/testee.dto';

@Component({
  selector: 'app-ssais-r-test-3',
  standalone: true,
  imports: [
    CommonModule,
    InputTextModule,
    FormsModule,
    ButtonModule,
    CardModule,
    RouterLink
  ],
  templateUrl: './test-3.component.html',
  styleUrl: './test-3.component.scss'
})
export class Test3Component implements OnInit, AfterViewInit {

  @ViewChildren('input') inputs: QueryList<ElementRef>;

  @Input() testInstance: TestInstanceDTO;
  @Input() testee: TesteeDTO;
  @Output() onGoBack = new EventEmitter();

  questions: QuestionDTO[] = [];
  cardA: QuestionDTO[] = [];
  cardB: QuestionDTO[] = [];
  totalScore: number;
  questionResponseObject = {};
  backButton = 'Cancel';

  constructor(private testService: TestService,
    private messageService: MessageService,
    private dateService: DateService
  ) {
    this.questionResponseObject = {
      next: (response: ApiResponse) => {
        this.questions = response.result;
        this.cardA = this.questions.filter(q => q.card === Card.A);
        this.cardB = this.questions.filter(q => q.card === Card.B);
        this.calculateTotalScore();
      }
    };
  }

  ngAfterViewInit(): void {

    this.testService.subtestcomponent = this;

    const intervalId = setInterval(() => {
      if (this.inputs && this.inputs.first && this.inputs.first.nativeElement === document.activeElement) {
        clearInterval(intervalId);
      } else if (this.inputs && this.inputs.first) {
        this.inputs.first.nativeElement.focus();
      }
    }, 100);
  }

  ngOnInit(): void {
    if (!this.testInstance) {
      this.testInstance = new TestInstanceDTO();
      this.testInstance.subtestId = Subtest.SSAISRTest3;
      this.getQuestions();
      this.backButton = 'Cancel';
    }
    else {
      this.getQuestionsWithAnswers();
      this.backButton = 'Back';
    }
  }

  getQuestionsWithAnswers() {
    this.testService.getQuestionsWithAnswers(this.testInstance.id).subscribe(this.questionResponseObject);
  }

  getQuestions() {
    this.testService.getQuestions(this.testInstance.subtestId).subscribe(this.questionResponseObject);
  }

  calculateTotalScore() {
    this.totalScore = 0;
    this.cardA.forEach(q => this.totalScore += q.score!);
    this.cardB.forEach(q => this.totalScore += q.score!);
  }

  pushAnswers(card: QuestionDTO[]) {
    card.forEach(q => {
      let answer = new AnswerDTO();
      answer.questionId = q.id;
      answer.response = q.response;
      answer.score = q.score;
      answer.id = q.answerId;

      let existingAnswer = this.testInstance.answers.find(x => x.questionId == answer.questionId) ?? false;

      if (!existingAnswer) {
        this.testInstance.answers!.push(answer);
      }
      else if (existingAnswer) {
        existingAnswer.response = q.response;
        existingAnswer.score = q.score;
      }
    });
  }

  submit() {

    this.testInstance.answers = [];

    this.pushAnswers(this.cardA);
    // this.pushAnswers(this.cardB);

    this.testInstance.dateOfTesting = new Date();
    this.testInstance.dateOfTestingString = this.dateService.dateToString(this.testInstance.dateOfTesting);
    this.testInstance.testeeId = this.testee.id;

    if (this.testInstance.id)
      this.testService.isEdit = true;

    this.testService.upsertTestInstance(this.testInstance).subscribe(this.testService.upsertTestInstanceResponseObject);
  }

  goBack() {
    this.onGoBack.emit();
  }
}
