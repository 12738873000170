import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownModule } from 'primeng/dropdown';
import { TestService } from '../../../shared/services/test.service';
import { ApiResponse } from '../../../shared/models/apiResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TesteeDTO } from '../../../shared/dtos/testee.dto';
import { TestDTO } from '../../../shared/dtos/test.dto';
import { SubtestDTO } from '../../../shared/dtos/subtest.dto';

@Component({
  selector: 'app-take-test',
  standalone: true,
  imports: [
    FormsModule,
    DropdownModule,
    ButtonModule
  ],
  templateUrl: './take-test.component.html',
  styleUrl: './take-test.component.scss'
})
export class TakeTestComponent implements OnInit {

  tests: TestDTO[] = [];
  subtests: SubtestDTO[] = [];
  selectedTestId: number;
  subtestId: number;
  testee: TesteeDTO;

  constructor(private testService: TestService,
    private messageService: MessageService,
    public config: DynamicDialogConfig,
    public ref: DynamicDialogRef
  ) {
  }

  ngOnInit(): void {
    if (this.config.data) {
      this.testee = this.config.data.testee;
    }

    this.getTests();
  }

  hideDialog() {
    this.ref.close();
  }

  getTests() {
    this.testService.getTests().subscribe({
      next: (response: ApiResponse) => {
        this.tests = response.result;
        this.selectedTestId = this.tests[0].id;
        this.getSubtests();
      }
    });
  }

  getSubtests() {
    this.subtests = this.tests.find(t => t.id === this.selectedTestId)!.subtests;
  }

  isFirstOrAfterSixMonths() {
    this.testService.isFirstOrAfterSixMonths(this.testee.id, this.selectedTestId, this.subtestId).subscribe({
      next: (response: ApiResponse) => {
        if (response.result) {
          this.ref.close(this.subtestId);
        }
        else if (!response.result) {
          this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Subtest already taken within the last 6 months.' });
        }
      }
    });
  }
}


