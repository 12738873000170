import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { UserDTO } from "../dtos/user.dto";

@Injectable({ providedIn: 'root' })
export class TesterService {

    apiUrl = environment.apiUrl;
    
    languages = [
        { label: 'English', value: 1 },
        { label: 'Afrikaans', value: 2 },
        { label: 'isiZulu', value: 3 },
        { label: 'Setswana', value: 4 },
        { label: 'siSwati', value: 5 },
        { label: 'Sesotho', value: 6 },
        { label: 'Sepedi', value: 7 },
        { label: 'Xitsonga', value: 8 },
        { label: 'Tshivenda', value: 9 },
        { label: 'isiXhosa', value: 10 },
        { label: 'isiNdebele', value: 11 }
      ];
  
      provinces = [
        { label: 'EasternCape', value: 1 },
        { label: 'FreeState', value: 2 },
        { label: 'Gauteng', value: 3 },
        { label: 'KwaZuluNatal', value: 4 },
        { label: 'Limpopo', value: 5 },
        { label: 'Mpumalanga', value: 6 },
        { label: 'NorthWest', value: 7 },
        { label: 'NorthernCape', value: 8 },
        { label: 'WesternCape', value: 9 }
      ];
    
    constructor(private http: HttpClient) { }

    getTesters(): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/tester/get-testers`);
    }

    getTester(id: number): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/tester/get-tester/${id}`);
    }

    getLoggedInTester(): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/tester/get-logged-in-tester`);
    }

    updateLoggedInTester(tester: UserDTO): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}/tester/update-logged-in-tester`, tester);
    }

    updateTester(tester: UserDTO): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}/tester/update-tester`, tester);
    }
}