import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { Table, TableModule } from 'primeng/table';
import { TesteeAddEditComponent } from './testee-add-edit/testee-add-edit.component';
import { TesteeDTO } from '../../shared/dtos/testee.dto';
import { TesteeService } from '../../shared/services/testee.service';
import { ApiResponse } from '../../shared/models/apiResponse';
import { ConfirmationService, MessageService } from 'primeng/api';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TakeTestComponent } from './take-test/take-test.component';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { Test1Component } from '../test-instances/ssais-r/test-1/test-1.component';
import { Test2Component } from '../test-instances/ssais-r/test-2/test-2.component';
import { Test3Component } from '../test-instances/ssais-r/test-3/test-3.component';
import { Test8Component } from '../test-instances/ssais-r/test-8/test-8.component';
import { Subtest } from '../../shared/enums/subtest.enum';
import { Language } from '../../shared/enums/language.enum';
import { RoleService } from '../../shared/services/role.service';
import { SchoolDTO } from '../../shared/dtos/school.dto';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-testees',
  standalone: true,
  imports: [
    TableModule,
    ButtonModule,
    InputTextModule,
    CardModule,
    CommonModule,
    TesteeAddEditComponent,
    ConfirmDialogModule,
    Test1Component,
    Test2Component,
    Test3Component,
    Test8Component,
    DropdownModule,
    FormsModule,
    TooltipModule
  ],
  templateUrl: './testees.component.html',
  styleUrl: './testees.component.scss'
})
export class TesteesComponent implements OnInit {

  @ViewChild('filter') filter!: ElementRef;

  testees: TesteeDTO[] = [];
  upsertTestee = false;
  isEdit = false;
  title: string = '';
  selectedTestee: TesteeDTO = new TesteeDTO();
  ref: DynamicDialogRef | undefined;
  isTakeTest = false;
  selectedSubtestId: number = 0;
  testee = new TesteeDTO();
  isAdmin = this.roleService.isAdmin();
  schools: SchoolDTO[] = [];
  fromYears: number[];
  toYears: number[];
  fromYear: number;
  toYear: number;

  get SubtestEnum(): typeof Subtest {
    return Subtest;
  }

  get Language(): typeof Language {
    return Language;
  }

  constructor(private testeeService: TesteeService,
    public dialogService: DialogService,
    private messageService: MessageService,
    private confirmationService: ConfirmationService,
    private roleService: RoleService
  ) { }

  ngOnInit() { 
    this.getSchools()
    this.getAllTesteesEnrolledYears();

    this.testeeService.refreshTestees.subscribe(() => {
      this.refresh();
    });
  }

  getAllTesteesEnrolledYears() {
    this.testeeService.getAllTesteesEnrolledYears().subscribe(
      {
        next: (response: ApiResponse) => {
          this.fromYears = response.result;
          this.toYears = response.result;

          this.fromYear = this.fromYears[0];
          this.toYear = this.toYears[this.toYears.length - 1];

          
        }
      });
  }

  getSchools() {
    this.testeeService.getSchools().subscribe(
      {
        next: (response: ApiResponse) => {
          this.schools = response.result;

          if (this.isAdmin) {
            this.schools.unshift({ id: 0, name: 'All' });
          }
        }
      });
  }

  getTestees(event: DropdownChangeEvent) {

    this.testeeService.getTestees(event.value, this.fromYear, this.toYear, 0, 120).subscribe({
      next: (response: ApiResponse) => {
        this.testees = response.result;

        this.testees.forEach(t => {
          if (t.testingLanguage === Language.English)
            t.testingLanguageString = 'English';
          else if (t.testingLanguage === Language.Afrikaans)
            t.testingLanguageString = 'Afrikaans';
          else if (t.testingLanguage === Language.Both)
            t.testingLanguageString = 'English & Afrikaans';
        });
      }
    });
  }

  upsert(testee?: TesteeDTO) {
    this.selectedTestee = testee ? testee : new TesteeDTO();
    this.upsertTestee = true;
  }

  confirm(testee: TesteeDTO) {

    this.selectedTestee = testee;

    this.confirmationService.confirm({
      header: 'Confirm',
      message: 'Are you sure you want to delete "' + this.selectedTestee.nameAndSurname + '"?',
      acceptIcon: 'pi pi-check mr-2',
      rejectIcon: 'pi pi-times mr-2',
      rejectButtonStyleClass: 'p-button-raised',
      acceptButtonStyleClass: 'p-button-raised p-button-danger',
      accept: () => {
        this.delete();
      }
    });
  }

  delete() {
    this.testeeService.deleteTestee(this.selectedTestee.id).subscribe({
      next: (response: ApiResponse) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Testee deleted successfully' });
        this.testees = this.testees.filter(t => t.id !== this.selectedTestee.id);
      }
    });
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal(
      (event.target as HTMLInputElement).value,
      'contains'
    );
  }

  clear(table: Table) {
    table.sortField = null;
    table.sortOrder = 0;
    table.clear();
    this.filter.nativeElement.value = '';
  }

  refresh() {
    this.selectedTestee = new TesteeDTO();
    this.upsertTestee = false;
    this.isTakeTest = false;
    this.selectedSubtestId = 0;
    this.getSchools();
    this.getAllTesteesEnrolledYears();
  }

  takeTest(testee: TesteeDTO) {
    this.ref = this.dialogService.open(TakeTestComponent, {
      data: {
        testee: testee
      },
      header: 'Take Test',
      contentStyle: { overflow: 'hidden' }
    });

    this.ref.onClose.subscribe((selectedSubtestId: number) => {
      if (selectedSubtestId) {
        this.selectedSubtestId = selectedSubtestId;
        this.isTakeTest = true;
        this.testee = testee;
      }
    });
  }
}