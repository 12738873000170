import { Routes } from '@angular/router';
import { MainComponent } from './main/main.component';
import { HomeComponent } from './main/home/home.component';
import { TesteesComponent } from './main/testees/testees.component';
import { TestInstancesComponent } from './main/test-instances/test-instances.component';
import { ProfileComponent } from './main/profile/profile.component';
import { AuthenticationGuard } from './shared/helpers/auth.guard';
import { TestersComponent } from './main/testers/testers.component';
import { inject } from '@angular/core';
import { AdminGuard } from './shared/helpers/admin.guard';
import { LoginComponent } from './auth/login/login.component';
import { RegisterComponent } from './auth/register/register.component';
import { VerifyEmailComponent } from './auth/verify-email/verify-email.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { ResetPasswordComponent } from './auth/reset-password/reset-password.component';
import { ExcelComponent } from './main/excel/excel.component';

export const routes: Routes = [
    { path: '', redirectTo: 'login', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'register', component: RegisterComponent },
    { path: 'verify-email', component: VerifyEmailComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'reset-password', component: ResetPasswordComponent },
    { path: 'app', component: MainComponent, canActivate: [() => inject(AuthenticationGuard).canActivate()],
        children: [
            { path: '', redirectTo: 'home', pathMatch: 'full' },
            { path: 'home', component: HomeComponent },
            { path: 'testers', component: TestersComponent, canActivate: [() => inject(AdminGuard).canActivate()]},
            { path: 'testees', component: TesteesComponent },
            { path: 'test-instances', component: TestInstancesComponent },
            { path: 'excel', component: ExcelComponent },
            { path: 'profile', component: ProfileComponent },
            { path: '**', redirectTo: 'testees' }
        ]
     },
    { path: '**', redirectTo: 'login' }
];
