import { Injectable } from "@angular/core";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({ providedIn: 'root' })
export class RoleService {

    constructor() { }

    isAdmin(): boolean {
        const token = JSON.parse(localStorage.getItem('accessToken')!);        
        const helper = new JwtHelperService();
        const decodedToken = helper.decodeToken(token);

        return decodedToken.role === 'admin';
    }
}