import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { InputTextModule } from 'primeng/inputtext';
import { Table, TableModule } from 'primeng/table';
import { ApiResponse } from '../../shared/models/apiResponse';
import { HttpErrorResponse } from '@angular/common/http';
import { ConfirmationService, MessageService } from 'primeng/api';
import { TestService } from '../../shared/services/test.service';
import { DateService } from '../../shared/services/date.service';
import { CommonModule } from '@angular/common';
import { Test1Component } from './ssais-r/test-1/test-1.component';
import { Test2Component } from './ssais-r/test-2/test-2.component';
import { Test3Component } from './ssais-r/test-3/test-3.component';
import { Test8Component } from './ssais-r/test-8/test-8.component';
import { Subtest } from '../../shared/enums/subtest.enum';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { TestInstanceDTO } from '../../shared/dtos/test-instance.dto';
import { RoleService } from '../../shared/services/role.service';
import { DropdownChangeEvent, DropdownModule } from 'primeng/dropdown';
import { TesteeService } from '../../shared/services/testee.service';
import { SchoolDTO } from '../../shared/dtos/school.dto';
import { FormsModule } from '@angular/forms';
import { TooltipModule } from 'primeng/tooltip';

@Component({
  selector: 'app-test-instances',
  standalone: true,
  imports: [
    TableModule,
    ButtonModule,
    InputTextModule,
    CardModule,
    CommonModule,
    Test1Component,
    Test2Component,
    Test3Component,
    Test8Component,
    ConfirmDialogModule,
    DropdownModule,
    FormsModule,
    TooltipModule
  ],
  templateUrl: './test-instances.component.html',
  styleUrl: './test-instances.component.scss'
})
export class TestInstancesComponent implements OnInit {

  @ViewChild('filter') filter!: ElementRef;

  tests: TestInstanceDTO[] = [];
  selectedTest: TestInstanceDTO;
  editTest = false;
  isAdmin = this.roleService.isAdmin();
  schools: SchoolDTO[] = [];
  fromYears: number[];
  toYears: number[];
  fromYear: number;
  toYear: number;

  get SubtestEnum(): typeof Subtest {
    return Subtest;
  }

  constructor(private testService: TestService,
    private messageService: MessageService,
    private dateService: DateService,
    private confirmationService: ConfirmationService,
    private roleService: RoleService,
    private testeeService: TesteeService,
  ) { }

  ngOnInit() {
    this.getSchools();
    this.getAllTesteesEntrolledYears();

    this.testService.refreshTests.subscribe(() => {
      this.refresh();
    });
  }

  getAllTesteesEntrolledYears() {
    this.testeeService.getAllTesteesEnrolledYears().subscribe(
      {
        next: (response: ApiResponse) => {
          this.fromYears = response.result;
          this.toYears = response.result;

          this.fromYear = this.fromYears[0];
          this.toYear = this.toYears[this.toYears.length - 1];
        }
      });
  }

  getSchools() {
    this.testeeService.getSchools().subscribe(
      {
        next: (response: ApiResponse) => {
          this.schools = response.result;

          if (this.isAdmin) {
            this.schools.unshift({ id: 0, name: 'All' });
          }
        }
      });
  }

  getTests(event: DropdownChangeEvent) {

    this.testService.getTestInstances(event.value, this.fromYear, this.toYear).subscribe(
      {
        next: (response: ApiResponse) => {
          response.result.forEach((test: TestInstanceDTO) => {
            test.dateOfTestingString = this.dateService.dateToString(test.dateOfTesting);
          });
          this.tests = response.result;
        }
      });
  }

  onGlobalFilter(table: Table, event: Event) {
    table.filterGlobal(
      (event.target as HTMLInputElement).value,
      'contains'
    );
  }

  clear(table: Table) {
    table.sortField = null;
    table.sortOrder = 0;
    table.clear();
    this.filter.nativeElement.value = '';
  }

  edit(test: TestInstanceDTO) {
    this.selectedTest = test;
    this.editTest = true;
  }

  refresh() {
    this.editTest = false;
    this.selectedTest = new TestInstanceDTO();
    this.getSchools();
    this.getAllTesteesEntrolledYears();
  }

  confirm(test: TestInstanceDTO) {

    this.selectedTest = test;

    this.confirmationService.confirm({
      header: 'Confirm',
      message: 'Are you sure you want to delete this test?',
      acceptIcon: 'pi pi-check mr-2',
      rejectIcon: 'pi pi-times mr-2',
      rejectButtonStyleClass: 'p-button-raised',
      acceptButtonStyleClass: 'p-button-raised p-button-danger',
      accept: () => {
        this.delete();
      }
    });
  }

  delete() {
    this.testService.deleteTest(this.selectedTest.id).subscribe({
      next: (response: ApiResponse) => {
        this.messageService.add({ severity: 'success', summary: 'Success', detail: 'Test deleted successfully' });
        this.tests = this.tests.filter(t => t.id !== this.selectedTest.id);
      }
    });
  }
}
