import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { LoginRequestDTO } from "../dtos/login-request.dto";
import { Observable, tap } from "rxjs";
import { ApiResponse } from "../models/apiResponse";
import { HttpClient } from "@angular/common/http";
import { TokenDTO } from "../dtos/token.dto";
import { Router } from "@angular/router";
import { RegisterationRequestDTO } from "../dtos/registration-request.dto";
import { ResetPasswordDTO } from "../dtos/reset-password.dto";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({ providedIn: 'root' })
export class AuthService {

    apiUrl = environment.apiUrl;
    appUrl = environment.appUrl;
    private _accessToken: string;
    private _expiresAt: number;
    private _expirationDate: Date;
    private tokenExpirationTimer: any;

    get accessToken() {
        return this._accessToken;
    }

    constructor(private http: HttpClient,
        private router: Router
    ) { }

    login(model: LoginRequestDTO): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}/user/login`, model).pipe(
            tap({
                next: (response: ApiResponse) => { this.handleAuthentication(response.result); }
            })
        );
    }

    autoLogin() {
        let url = window.location.href;;
        if (this.isAuthenticated() && url.indexOf('login') !== -1 || url.indexOf('mfa') !== -1 || url[url.length - 1] === '/') {
            this.router.navigate(['/app']);
        }
    }

    handleAuthentication(tokenDTO: TokenDTO) {
        this._accessToken = tokenDTO.accessToken;
        this._expiresAt = +JSON.parse(atob(this._accessToken.split('.')[1])).exp;
        this._expirationDate = new Date(this._expiresAt * 1000);
        this.autoLogout(this._expirationDate.getTime() - new Date().getTime());
        localStorage.setItem('accessToken', JSON.stringify(this._accessToken));
    }

    autoLogout(expirationDuration: number) {
        this.tokenExpirationTimer = setTimeout(() => {
            this.logout();
        }, expirationDuration);
    }

    logout() {
        localStorage.removeItem('accessToken');

        if (this.tokenExpirationTimer) {
            clearTimeout(this.tokenExpirationTimer);
        }

        this.tokenExpirationTimer = null;

        this.clearAuthState();
        this.router.navigate(['login']);
    }

    clearAuthState() {
        this._accessToken = '';
        this._expiresAt = 0;
        this._expirationDate = new Date();
    }

    isAuthenticated(): boolean {
        this._accessToken = localStorage.getItem('accessToken')!;

        if (this._accessToken) {
            const helper = new JwtHelperService();
            const decodedToken = helper.decodeToken(this._accessToken);
            this._expiresAt = decodedToken.exp;
            this._expirationDate = new Date(this._expiresAt * 1000);
            this.autoLogout(this._expirationDate.getTime() - new Date().getTime());
            return new Date() < this._expirationDate;
        }

        return false;
    }

    register(model: RegisterationRequestDTO): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}/user/register`, model);
    }

    verifyEmail(userId: string, code: string): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/user/verify-email?userId=${userId}&code=${code}`);
    }

    forgotPassword(email: string): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/user/forgot-password?email=${email}`);
    }

    resetPassword(userId: string, token: string, newPassword: string): Observable<any> {
        let model: ResetPasswordDTO = {
            userId: userId,
            token: token,
            newPassword: newPassword
        };
        return this.http.post<any>(`${this.apiUrl}/user/reset-password`, model);
    }
}   