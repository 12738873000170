<p-card id="test">
    <div style="float: right; cursor: pointer; color: purple;" (click)="goBack()"><i class="pi pi-times"></i></div>
    <br>
    <form #testForm="ngForm" id="test8Form" name="test8Form" (ngSubmit)="submit()">
        <table class="table1">
            <tr>
                <td colspan="7" class="center">TEST 8: MISSING PARTS/TOETS 8: ONTBREKENDE DELE</td>
            </tr>
            <tr>
                <td colspan="3" class="center">Item</td>
                <td rowspan="2" colspan="2" class="center">Response<br>Respons</td>
                <td rowspan="2" class="center" style="width: 40px;">Time<br>(Optional)<br>Tyd<br>(Optioneel)</td>
                <td rowspan="2" class="center">Score<br>Telling</td>
            </tr>
            <tr>
                <td>Num</td>
                <td>Missing part<br>Ontbrekende deel</td>
                <td class="center">Time limit<br>Tydbeperking</td>
            </tr>
            <tr>
                <td colspan="7" class="center bold bg-gray-500">Card A</td>
            </tr>
            <tr *ngFor="let q of cardA; index as i;">
                <td>{{q.number}}</td>
                <td>{{q.item}}<br>{{q.itemAfrikaans}}</td>
                <td class="center">{{q.timeLimit}}”</td>
                <td style="border-right: none; width: 30px;">
                    <sup>{{q.extra}}</sup>
                </td>
                <td style="border-left: none;">
                    <input #input [id]="'input' + i" [name]="'input' + i" type="text" maxlength="500"
                        [(ngModel)]="q.response" class="input" style="width: 100%">
                </td>
                <td class="center"><input #time [id]="'time' + i" [name]="'time' + i" type="number" [(ngModel)]="q.time"
                        min="0" maxlength="2" style="width: 40px;">”</td>
                <td><input #score [id]="'score' + i" [name]="'score' + i" type="number" [(ngModel)]="q.score" min="0"
                        maxlength="2" (change)="calculateTotalScore()" class="center" style="width: 60px;"></td>
            </tr>
            <tr>
                <td colspan="7" class="center bold bg-orange-200">Card B</td>
            </tr>
            <tr *ngFor="let q of cardB index as i;">
                <td>{{q.number}}</td>
                <td></td>
                <td class="center" style="width: 40px;">{{q.timeLimit}}”</td>
                <td style="border-right: none;"></td>
                <td style="border-left: none;"></td>
                <td class="center" style="border-right: none;"><input style="width: 40px;" disabled>”</td>
                <td></td>
            </tr>
            <tr>
                <td colspan="6" class="bold bg-gray-500">TOTAL SCORE</td>
                <td><input name="totalScore" type="number" class="center" style="width: 60px;" [ngModel]="totalScore"
                        disabled></td>
            </tr>
        </table>
        <p>Comments/Opmerkings:</p>
        <textarea d="comments" name="comments" rows="3" cols="83" pInputTextarea
            [(ngModel)]="testInstance.comments"></textarea>
        <br>
        <p-button [raised]="true" type="submit" label="Submit" styleClass="mr-2"
            [disabled]="testForm.invalid"></p-button>
        <p-button [outlined]="true" type="button" [label]="backButton" (onClick)="goBack()"></p-button>
    </form>
</p-card>