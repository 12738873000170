import { AddressDTO } from "./address.dto";

export class RegisterationRequestDTO {
    userName: string;
    firstName: string;
    lastName: string;
    hpcsaRegisteredProfession: string;
    hpcsaRegistrationNumber: string;
    password: string;
    idNumber: string;
    practice: string;
    telHome: string;
    telWork: string;
    languageId: number;

    address: AddressDTO;
}