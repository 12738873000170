import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { TesteeDTO } from "../dtos/testee.dto";
import { Observable, Subject } from "rxjs";
import { environment } from "../../../environments/environment";
import { Constants } from "../constants";
import { SchoolDTO } from "../dtos/school.dto";

@Injectable({ providedIn: 'root' })
export class TesteeService {

    apiUrl = environment.apiUrl;
    refreshTestees = new Subject();

    constructor(private http: HttpClient) { }

    getTestees(schoolId: number, fromYear: number, toYear: number, startAge?: number, endAge?: number): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/testee?schoolId=${schoolId}&fromYear=${fromYear}&toYear=${toYear}&startAge=${startAge}&endAge=${endAge}`);
    }

    upsertTestee(testeeDTO: TesteeDTO): Observable<any> {
        return this.http.post<any>(`${this.apiUrl}/testee`, testeeDTO);
    }

    deleteTestee(id: number): Observable<any> {
        return this.http.delete<any>(`${this.apiUrl}/testee/${id}`);
    }

    getTesteesExcel(schoolId: number, fromYear: number, toYear: number, startAge: number, endAge: number): Observable<any> {
        return this.http.get(`${this.apiUrl}/testee/gettesteesexcel?schoolId=${schoolId}&fromYear=${fromYear}&toYear=${toYear}&startAge=${startAge}&endAge=${endAge}`, {
            responseType: 'blob',
            headers: new HttpHeaders().append('Content-Type', Constants.ExcelMimeType)
        });
    }

    getSsais_RExcel(schoolId: number, fromYear: number, toYear: number, startAge: number, endAge: number): Observable<any> {
        return this.http.get(`${this.apiUrl}/testee/getssais_rexcel?schoolId=${schoolId}&fromYear=${fromYear}&toYear=${toYear}&startAge=${startAge}&endAge=${endAge}`, {
            responseType: 'blob',
            headers: new HttpHeaders().append('Content-Type', Constants.ExcelMimeType)
        });
    }

    getSchools(): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/testee/getschools`);
    }

    getAllTesteesEnrolledYears(): Observable<any> {
        return this.http.get<any>(`${this.apiUrl}/testee/getalltesteesenrolledyears`);
    }

}