import { Injectable } from "@angular/core";
import { RoleService } from "../services/role.service";

@Injectable({ providedIn: 'root' })
export class AdminGuard {
    constructor(private roleService: RoleService) { }

    canActivate(): boolean {
        return this.roleService.isAdmin();          
    }
}