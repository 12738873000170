import { Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { CardModule } from 'primeng/card';
import { TesterService } from '../../shared/services/tester.service';
import { ApiResponse } from '../../shared/models/apiResponse';
import { UserDTO } from '../../shared/dtos/user.dto';
import { MessageService } from 'primeng/api';
import { HttpErrorResponse } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { DropdownModule } from 'primeng/dropdown';
import { TesterComponent } from '../../shared/components/tester/tester.component';

@Component({
  selector: 'app-profile',
  standalone: true,
  imports: [
    CardModule,
    FormsModule,
    ButtonModule,
    CommonModule,
    DropdownModule,
    TesterComponent
  ],
  templateUrl: './profile.component.html',
  styleUrl: './profile.component.scss'
})
export class ProfileComponent implements OnInit {

  tester: UserDTO = new UserDTO();
  heading: string;

  isProfile: boolean = true;

  constructor(private testerService: TesterService,
    private messageService: MessageService
  ) { }

  ngOnInit(): void {
    this.testerService.getLoggedInTester().subscribe(
      {
        next: (response: ApiResponse) => {
          this.tester = response.result;
          this.heading = this.tester.firstName + ' ' + this.tester.lastName + (this.tester.lastName[this.tester.lastName.length - 1] === 's' ? '\'' : '\'s') + ' Profile';
        }
      });
  }
}
