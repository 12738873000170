
<form #testerForm="ngForm" id="testerForm" (ngSubmit)="submit()">
    <div class="formgrid grid">        
        <div class="field col">
            <label for="userName">User Name (Email)</label>
            <input id="userName" type="text" [(ngModel)]="tester.userName" name="userName" required disabled
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
        </div>
        <div class="field col" *ngIf="showBackButton">
            <div style="float: right; cursor: pointer; color: purple;" (click)="goBack()"><i class="pi pi-times"></i></div>
        </div>
    </div>
    <div class="formgrid grid">
        <div class="field col">
            <label for="firstname" class="required">First Name</label>
            <input id="firstname" type="text" [(ngModel)]="tester.firstName" name="firstName" required
                #firstName="ngModel"
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            <small *ngIf="firstName.invalid && firstName.touched">
                First Name is required.
            </small>
        </div>
        <div class="field col">
            <label for="lastname" class="required">Last Name</label>
            <input id="lastname" type="text" [(ngModel)]="tester.lastName" name="lastName" required
                #lastName="ngModel"
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            <small *ngIf="lastName.invalid && lastName.touched">
                Last Name is required.
            </small>
        </div>
    </div>
    <div class="formgrid grid">
        <div class="field col">
            <label for="hpcsaRegisteredProfession" class="required">HPCSA Registered Profession</label>
            <input id="hpcsaRegisteredProfession" type="text" [(ngModel)]="tester.hpcsaRegisteredProfession"
                name="hpcsaRegisteredProfession" required #hpcsaRegisteredProfession="ngModel"
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            <small *ngIf="hpcsaRegisteredProfession.invalid && hpcsaRegisteredProfession.touched">
                HPCSA Registered Profession is required.
            </small>
        </div>
        <div class="field col">
            <label for="hpcsaRegistrationNumber" class="required">HPCSA Registration Number</label>
            <input id="hpcsaRegistrationNumber" type="text" [(ngModel)]="tester.hpcsaRegistrationNumber"
                name="hpcsaRegistrationNumber" required #hpcsaRegistrationNumber="ngModel"
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            <small *ngIf="hpcsaRegistrationNumber.invalid && hpcsaRegistrationNumber.touched">
                HPCSA Registration Number is required.
            </small>
        </div>
    </div>
    <div class="formgrid grid">
        <div class="field col">
            <label for="idNumber" class="required">Identity Number</label>
            <input id="idNumber" type="text" [(ngModel)]="tester.idNumber" name="idNumber" required
                #idNumber="ngModel"
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            <small *ngIf="idNumber.invalid && idNumber.touched">
                Identity Number is required.
            </small>
        </div>
        <div class="field col">
            <label for="practice" class="required">Practice</label>
            <input id="practice" type="text" [(ngModel)]="tester.practice" name="practice" required
                #practice="ngModel"
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            <small *ngIf="practice.invalid && practice.touched">
                Practice is required.
            </small>
        </div>
    </div>
    <div class="formgrid grid">
        <div class="field col-4">
            <label for="telHome">Tel Home</label>
            <input id="telHome" type="text" [(ngModel)]="tester.telHome" name="telHome" #telHome="ngModel"
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
        </div>
        <div class="field col-4">
            <label for="telWork">Tel Work</label>
            <input id="telWork" type="text" [(ngModel)]="tester.telWork" name="telWork" #telWork="ngModel"
                class="text-base text-color surface-overlay p-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
        </div>
        <div class="field col-4">
            <label for="language" class="required">Language</label>
            <p-dropdown id="language" [options]="languages" optionLabel="label" optionValue="value"
                [(ngModel)]="tester.languageId" name="language" required #language="ngModel" scrollHeight="450px"
                styleClass="mr-3 w-full">
            </p-dropdown>
            <small *ngIf="language.invalid && language.touched">
                Language is required.
            </small>
        </div>
    </div>
    <h5>Physical Address</h5>
    <div class="flex-container mb-2">
        <div>
            <label for="street" class="required">Street</label>
            <input id="street" type="text" [(ngModel)]="tester.address.street" name="street" required
                #street="ngModel"
                class="text-base text-color surface-overlay p-2 mt-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            <small *ngIf="street.invalid && street.touched">
                Street is required.
            </small>
        </div>
        <div>
            <label for="suburb" class="required">Suburb</label>
            <input id="suburb" type="text" [(ngModel)]="tester.address.suburb" name="suburb" required
                #suburb="ngModel"
                class="text-base text-color surface-overlay p-2 mt-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            <small *ngIf="suburb.invalid && suburb.touched">
                Suburb is required.
            </small>
        </div>
        <div>
            <label for="city" class="required">City</label>
            <input id="city" type="text" [(ngModel)]="tester.address.city" name="city" required #city="ngModel"
                class="text-base text-color surface-overlay p-2 mt-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            <small *ngIf="city.invalid && city.touched">
                City is required.
            </small>
        </div>
        <div>
            <label for="postalCode" class="required">Postal Code</label>
            <input id="postalCode" type="text" [(ngModel)]="tester.address.postalCode" name="postalCode" required
                #postalCode="ngModel"
                class="text-base text-color surface-overlay p-2 mt-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            <small *ngIf="postalCode.invalid && postalCode.touched">
                Postal Code is required.
            </small>
        </div>
        <div>
            <label for="province" class="required">Province</label>
            <p-dropdown id="province" [options]="provinces" optionLabel="label" optionValue="value"
                [(ngModel)]="tester.address.provinceId" name="province" required #province="ngModel"
                scrollHeight="400px" styleClass="mt-2 mr-3 w-full">
            </p-dropdown>
            <small *ngIf="province.invalid && province.touched">
                Province is required.
            </small>
        </div>
        <div>
            <label for="country" class="required">Country</label>
            <input id="country" type="text" [(ngModel)]="tester.address.country" name="country" required
                #country="ngModel"
                class="text-base text-color surface-overlay p-2 mt-2 border-1 border-solid surface-border border-round appearance-none outline-none focus:border-primary w-full">
            <small *ngIf="country.invalid && country.touched">
                Country required.
            </small>
        </div>
        <div class="flex align-items-center gap-1 mb-3" *ngIf="showBackButton">
            <p-checkbox inputId="active" [binary]="true" name="active" #active="ngModel"
                [(ngModel)]="tester.isActivated"></p-checkbox>
            <label for="active">Active</label>
        </div>
    </div>
    <p-button [raised]="true" label="Submit" type="submit" class="mr-2" [disabled]="testerForm.invalid"></p-button>
    <p-button [outlined]="true" label="Back" type="button" (onClick)="goBack()" *ngIf="showBackButton"></p-button>
</form>