<div class="px-5 min-h-screen flex justify-content-center align-items-center">
    <div class="border-1 surface-border surface-card border-round py-7 px-4 md:px-7 z-1">
        <div class="mb-4" *ngIf="!isEmailSubmitted">
            <span class="text-600 font-medium">Please provide your email address</span>
        </div>
        <div class="p-input-icon-left w-full mb-4" *ngIf="!isEmailSubmitted">
            <i class="pi pi-envelope"></i>
            <input id="email" type="text" pInputText class="w-full" placeholder="Email" [(ngModel)]="email">
        </div>
        <p-button [raised]="true" label="Submit" class="w-auto mr-2" (click)="submit()" *ngIf="!isEmailSubmitted" [disabled]="email == null || email === ''"></p-button>
        <p-button [outlined]="true" routerLink="/login" *ngIf="!isEmailSubmitted">Back</p-button>
        <div *ngIf="isEmailSubmitted">
            <span class="text-600 font-medium">An email has been sent to {{email}}. Please click on the "Reset Password" link in the email to proceed with resetting your password. The link will expire in 15 minutes.</span>
        </div>
    </div>
</div>
